<template>
  <div class="box-body">
    <div class="form-group">
      <div class="table-responsive">
        <div class="box box-default box-solid">
          <div class="box-body no-padding table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th class="text-center">#</th>
                  <th class="text-center">Concepto</th>
                  <th class="text-center">Total $</th>
                  <th class="text-center">Total Bss</th>
                  <th class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in rows" :key="index" track-by="$index">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">
                    <div class="input-group">
                      <input
                        name="concepto[]"
                        type="text"
                        class="form-control"
                        v-model="row.concepto"
                        required
                      />
                    </div>
                  </td>
                  <td class="text-right">
                    <div class="input-group">
                      <input
                        class="form-control"
                        type="number"
                        name="totalRengDs[]"
                        step="0.01"
                        lang="en"
                        required
                        @change="totReciboDS(index)"
                        @keydown.enter="$event.preventDefault()"
                        placeholder="0"
                        v-model="row.totalRengDs"
                      />
                    </div>
                  </td>
                  <td class="text-right">
                    <div class="input-group">
                      <input
                        class="form-control"
                        type="number"
                        name="totalRengBs[]"
                        step="0.01"
                        lang="en"
                        required
                        @change="totReciboBS(index)"
                        @keydown.enter="$event.preventDefault()"
                        placeholder="0"
                        v-model="row.totalRengBs"
                      />
                    </div>
                  </td>
                  <td class="text-center">
                    <button
                      @click="removeRow(index)"
                      type="button"
                      class="btn btn-link text-red"
                    >
                      <i class="fa fa-2x fa-times"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="7">
                    <button
                      type="button"
                      @click="addRow()"
                      class="btn btn-flat btn-primary btn-block"
                      :disabled="!agregar"
                    >
                      AGREGAR <i class="fa fa-list-ul"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <th colspan="4">
                    <div class="form-group">
                      <label for="comentario"
                        >Informaci&oacute;n Adicional</label
                      >
                      <textarea
                        name="comentario"
                        id="comentario"
                        rows="4"
                        class="form-control"
                        placeholder=""
                        maxlength="60"
                        v-model="adicional"
                      ></textarea>
                    </div>
                  </th>
                  <th colspan="3">
                    <h3>Total BS: {{ formatoMonto(totalReciboBS) }}</h3>
                    <input
                      type="text"
                      name="totNeto"
                      id="totNeto"
                      v-model="totalReciboBS"
                      class="hidden"
                    />
                    <h3>Total $: {{ formatoMonto(totalReciboDS) }}</h3>
                    <input
                      type="text"
                      name="totNetoDs"
                      id="totNetoDs"
                      v-model="totalReciboDS"
                      class="hidden"
                    />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div v-bind:class="{ hidden: ocultaOverlay, overlay: true }">
            <span
              ><i class="fa fa-2x fa-spin fa-refresh"></i>
              {{ msgCargando }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ocultaOverlay: true,
      msgCargando: "Cargando información...",
      adicional: this.comentario,
      agregar: false,
      rows: [],
    };
  },
  computed: {
    totalReciboBS: function () {
      if (!this.rows) return;
      let total = 0;
      for (let i = 0; i < this.rows.length; i++) {
        total += this.rows[i].totRengBs;
      }
      return parseFloat(total).toFixed(2);
    },
    totalReciboDS: function () {
      if (!this.rows) return;
      let total = 0;
      for (let i = 0; i < this.rows.length; i++) {
        total += this.rows[i].totRengDs;
      }
      return parseFloat(total).toFixed(2);
    },
  },
  methods: {
    seleccionados: function () {
      let art = [];
      for (let i = 0; i < this.rows.length; i++) {
        art.push(this.rows[i].articulo);
      }
      return art;
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatoLabel(item) {
      return `${item.value} - ${item.label}`;
    },
    notificacion: function (info) {
      $.notify(
        {
          icon: info.icon,
          title: info.title,
          message: info.e,
        },
        {
          element: "body",
          type: info.type,
          template:
            '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
            '<h4><button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
            '<span data-notify="icon"></span> ' +
            '<span data-notify="title">{1}</span></h4> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            "</div>" +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            "</div>",
        }
      );
    },
    addRow: function () {
      try {
        this.rows.splice(this.rows.length + 1, 0, {
          concepto: "",
          totRengBs: 0,
          totRengDs: 0,
        });
        this.agregar = false;
      } catch (e) {
        this.notificacion({
          icon: "fa fa-ban",
          title: "Error",
          e: e,
          type: "danger",
        });
      }
    },
    removeRow: function (index) {
      this.rows.splice(index, 1);
      this.agregar = true;
    },
  },
};
</script>