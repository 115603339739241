export function getClients() {
  this.clientes = null;
  axios
    .post(this.urlclientes)
    .then((response) => {
      let data = $.map(response.data, function (obj) {
        obj.text = obj.value.replace(/v|j|g|e|-/gi, "") + " - " + obj.label;
        return obj;
      });
      this.clientes = data;
    })
    .catch((e) => {
      this.notificacion({
        icon: "fa fa-ban",
        title: "Error",
        e: e,
        type: "danger",
      });
    });
}
