export const CustomerOrderProps = {
  urlclientes: {
    type: String,
    default: "/tabla/cliente",
  },
  urlarticulos: {
    type: String,
    default: "/tabla/articulo",
  },
  urlfacturas: {
    type: String,
    default: "/facturas/cobrar",
  },
  urlshowfacturas: {
    type: String,
    default: "/facturas",
  },
  urltransportes: {
    type: String,
    default: "/tabla/transportes",
  },
  urlshowprod: {
    type: String,
    default: "/",
  },
  numorden: {
    default: "",
  },
  condcli: {
    default: "",
  },
  nombrecli: {
    default: "",
  },
  nombreven: {
    default: "",
  },
  rifcli: {
    default: "",
  },
  pedido: {
    type: Boolean,
    default: false,
  },
  fecha: "",
  comentario: {
    default: "",
  },
  urlreng: {
    default: "",
  },
  vendedor: {
    default: 1,
  },
};

export function sendNotification(info) {
  $.notify(
    {
      icon: info.icon,
      title: info.title,
      message: info.e,
    },
    {
      element: "body",
      type: info.type,
      template:
        '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
        '<h4><button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
        '<span data-notify="icon"></span> ' +
        '<span data-notify="title">{1}</span></h4> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        "</div>" +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        "</div>",
    }
  );
}

export function getOrderRows(orden) {
  if (orden === "") return;
  axios
    .post(this.urlreng, { id: orden })
    .then((response) => {
      this.rows = response.data;
      this.agregar = true;
      this.ocultaOverlay = true;
      this.totalPedidoBS;
      this.totalPedidoDS;
      setTimeout(function() {
        let elements = document.getElementsByClassName("item");
        for (let i = 0; i < elements.length; i++) {
          let element = elements[i];
          element.style.fontSize = "1.5rem";
        }
      }, 800);
    })
    .catch((e) => {
      this.notificacion({
        icon: "fa fa-ban",
        title: "Error",
        e: e,
        type: "danger",
      });
    });
}

export function getSelected() {
  let art = [];
  for (let i = 0; i < this.rows.length; i++) {
    art.push(this.rows[i].articulo);
  }
  return art;
}

export function amountFormat(value) {
  let val = (value / 1).toFixed(2).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function formatLabel(item) {
  return `${item.value} - ${item.label}`;
}

export function totRengBS(index) {
  this.rows[index].totRengBs = this.rows[index].precio * this.rows[index].cantidad;
}

export function totRengDS(index) {
  this.rows[index].totRengDs = this.rows[index].precio_divisa * this.rows[index].cantidad;
}

export function removeRow(index) {
  this.rows.splice(index, 1);
  this.agregar = true;
}

export function validateRow(index) {
  if (this.condicion != "") {
    const product = this.articulos.find(
      (item) => item.value === this.rows[index].articulo
    );
    if (!product) {
      this.rows[index].nombre = "";
      this.rows[index].cantidad = 0;
      this.rows[index].totRengBs = 0;
      this.rows[index].totRengDs = 0;
      this.rows[index].cantMax = 0;
      this.rows[index].imagen = "/logo.png";
      this.rows[index].precio = 0;
      this.rows[index].precio_divisa = 0;
      this.rows[index].hasTax = false;
      return;
    }
    this.articulo = product;
    this.rows[index].nombre = product.label;
    this.rows[index].cantidad = this.rows[index].cantidad > 0 ? this.rows[index].cantidad : 0;
    this.rows[index].totRengBs = this.rows[index].totRengBs > 0 ? this.rows[index].totRengBs : 0;
    this.rows[index].totRengDs = this.rows[index].totRengDs > 0 ? this.rows[index].totRengDs : 0;
    this.rows[index].hasTax = Number(product.impuesto) === 1;
    this.rows[index].cantMax = product.stock;
    this.rows[index].imagen = product.imagen;
    if (this.condicion == "1") {
      this.rows[index].precio = product.precio1 || 0;
      this.rows[index].precio_divisa = product.precio2 || 0;
    } else {
      this.rows[index].precio = product.precio3 || 0;
      this.rows[index].precio_divisa = product.precio4 || 0;
    }
    const a = this.rows[index].articulo;
    if (this.rows[index].cantidad > this.rows[index].cantMax) {
      this.rows[index].cantidad = null;
      let msj =
        "Superó la cantidad máxima del artículo en el ítem # " + (index + 1);
      this.notificacion({
        icon: "fa fa-ban",
        title: "Advertencia",
        e: msj,
        type: "warning",
      });
    }
    let rep = this.rows.filter(function(item) {
      return item.articulo === a;
    });
    this.agregar =
      rep.length === 1 && a !== "" && this.rows[index].cantidad > 0;
    if (!(rep.length === 1)) {
      this.rows[index].articulo = "";
      let msj =
        "El artículo en el ítem #" +
        (index + 1) +
        " está repetido, seleccione otro.";
      this.notificacion({
        icon: "fa fa-ban",
        title: "Advertencia",
        e: msj,
        type: "warning",
      });
    }
  } else {
    this.notificacion({
      icon: "fa fa-ban",
      title: "Advertencia",
      e: "Debe seleccionar un cliente",
      type: "warning",
    });
  }
}

export function addRow() {
  try {
    this.rows.splice(this.rows.length + 1, 0, {
      articulo: "",
      cantidad: 0,
      precio: 0.0,
      precio_divisa: 0.0,
      totRengBs: 0,
      totRengDs: 0,
      cantMax: 0,
      hasTax: false,
      imagen: "/logo.png",
    });
    this.agregar = false;
    setTimeout(function() {
      let elements = document.getElementsByClassName("item");
      for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        element.style.fontSize = "1.5rem";
      }
    }, 800);
  } catch (e) {
    this.notificacion({
      icon: "fa fa-ban",
      title: "Error",
      e: e,
      type: "danger",
    });
  }
}

export function totalPedidoBS() {
  if (!this.rows) return;
  let total = 0;
  for (let i = 0; i < this.rows.length; i++) {
    total += this.rows[i].totRengBs;
  }
  return parseFloat(total).toFixed(2);
}

export function totalPedidoDS() {
  if (!this.rows) return;
  let total = 0;
  for (let i = 0; i < this.rows.length; i++) {
    const tax = this.rows[i].hasTax ? 1.16 : 1;
    total += this.rows[i].totRengDs * tax;
  }
  return parseFloat(total).toFixed(2);
}
