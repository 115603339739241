<template>
    <!--<div id="app">-->
        <!--<vueSignature ref="signature" :sigOption="option" :w="'100%'" :h="'200px'"></vueSignature>-->
        <!--<input id="base64" type="text" val="base64">-->
        <!--&lt;!&ndash;<vueSignature ref="signature" :sigOption="option"></vueSignature>&ndash;&gt;-->
        <!--<a class="btn btn-default" @click="save">Guardar</a>-->
        <!--<a class="btn btn-default" @click="clear">Limpiar</a>-->
        <!--<a class="btn btn-default" @click="undo">Deshacer</a>-->
        <!--&lt;!&ndash;<button @click="addWaterMark">addWaterMark</button>&ndash;&gt;-->
    <!--</div>-->
    <form id="form" v-bind:action="urlstore" method="post">
        <input type="hidden" name="_token" :value="csrf"/>
        <input id="ubicacion" name="ubicacion" type="text" class="hidden">
        <div class="row">
            <div class="col-md-7">
                <div class="form-group">
                    <div v-if="!clientes">
                        <i class="fa fa-2x fa-spin fa-refresh"></i> Cargando clientes...
                    </div>
                    <div v-else="clientes">
                        <model-select :options="clientes" v-model="cliente"
                                      placeholder="[Seleccione un cliente]"></model-select>
                        <input type="text" name="cliente" id="cliente" v-model="cliente.value" hidden/>
                    </div>
                </div>
            </div>
        </div>
        <div class="box box-default box-solid">
            <div class="box-body">
                <div id="app" style="padding: 5px;">
                    <vueSignature ref="signature" :sigOption="option" :w="'100%'" :h="'200px'"></vueSignature>
                    <br>
                    <input id="firma" name="firma" class="hidden" type="text">
                    <a class="btn btn-default pull-right" @click="undo">Deshacer</a>
                    <a class="btn btn-default pull-right" @click="clear">Limpiar</a>
                </div>
                <div class="form-group"><br><br>
                    <label for="comentario">Informaci&oacute;n Adicional</label>
                    <textarea name="comentario" id="comentario" rows="4" class="form-control" placeholder="" maxlength="60" v-model="adicional"></textarea>
                </div>
            </div>
            <div v-bind:class="{'hidden': ocultaOverlay, 'overlay': true}">
                <span><i class="fa fa-2x fa-spin fa-refresh"></i> {{ msgCargando }}</span>
            </div>
        </div>
        <div class="box-footer">
            <a class="btn btn-flat btn-default" v-bind:href="urlhome"><i class="fa fa-arrow-left"></i> Volver</a>
            <button @click="save($event)" class="btn btn-flat btn-primary guardar">
                <i class="fa fa-save"> </i> Guardar
            </button>
        </div>
    </form>
</template>
<script>
    import { ModelSelect } from 'vue-search-select';
    import vueSignature from "vue-signature"
    export default {
        components: {ModelSelect},
        props: {
            urlclientes: {
                type: String,
                default: '/tabla/cliente'
            },
            urlhome: {
                type: String,
                default: '/'
            },
            urlstore: {
                type: String,
                default: '/reportes/facturas'
            },
            comentario: {
                default: ''
            },
        },
        data() {
            return {
                option:{
                    penColor:"rgb(0, 0, 0)",
                    backgroundColor:"rgb(240,240,240)"
                },
                ocultaOverlay: true,
                msgCargando: 'Por favor espere ...',
                adicional: this.comentario,
                cliente: {text: "", value: "", codigo: ""},
                clientes: [{text: "Seleccione", value: "", codigo: ""}],
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            };
        },
        mounted() {
            this.ocultaOverlay = false;
            this.getClientes();
            this.geolocalizacion();
        },
        methods:{
            notificacion: function (info){
                $.notify({
                    icon: info.icon,
                    title: info.title,
                    message: info.e,
                }, {
                    element: 'body',
                    type: info.type,
                    template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                        '<h4><button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                        '<span data-notify="icon"></span> ' +
                        '<span data-notify="title">{1}</span></h4> ' +
                        '<span data-notify="message">{2}</span>' +
                        '<div class="progress" data-notify="progressbar">' +
                        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                        '</div>' +
                        '<a href="{3}" target="{4}" data-notify="url"></a>' +
                        '</div>'
                });
            },
            geolocalizacion: function() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(this.guardarUbicacion);
                } else {
                    this.notificacion({icon: "fa fa-info", title: "Geolocalización Fallida", e: 'El navegador utilizado no soporta la geolocalización', type: "info"});
                }
            },
            guardarUbicacion: function (ubicacion) {
                let geolocalizacion = 'Longitud: ' + ubicacion.coords.longitude + ', Latitud: ' + ubicacion.coords.latitude;
                $('#ubicacion').val(geolocalizacion);
            },
            getClientes: function () {
                this.clientes = null;
                axios.post(this.urlclientes)
                    .then(response => {
                        let data = $.map(response.data, function (obj) {
                            obj.text = obj.value.replace(/v|j|g|e|-/gi, '') + " - " + obj.label;
                            return obj;
                        });
                        this.clientes = data;
                        this.ocultaOverlay = true;
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
            save: function (event) {
                event.preventDefault();
                $('.guardar').attr('disabled', 'disabled');
                if($('#ubicacion').val() == null || $('#ubicacion').val() == ''){
                    this.notificacion({icon: "fa fa-ban", title: "Error", e: "Debe permitir la geolocalizacion, por favor permita el accesso a su ubicación", type: "warning"});
                    $('.guardar').attr('disabled', false);
                }else{
                    if (!this.cliente.codigo){
                        this.notificacion({icon: "fa fa-warning", title: "Error", e: "Seleccione un cliente", type: "warning"});
                        $('.guardar').attr('disabled', false);
                    }else {
                        if (this.$refs.signature.isEmpty()) {
                            this.notificacion({
                                icon: "fa fa-ban",
                                title: "Error",
                                e: "El cliente debe ingresar la firma",
                                type: "danger"
                            });
                            $('.guardar').attr('disabled', false);
                        } else {
                            let png = this.$refs.signature.save();
                            $('#firma').val(png);
                            $("#form").submit();
                        }
                    }
                }
            },
            clear(){
                var _this = this;
                _this.$refs.signature.clear();
            },
            undo(){
                var _this = this;
                _this.$refs.signature.undo();
            },
        }
    }
</script>