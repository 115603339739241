<template>
  <div v-if="!clientes">
    <i class="fa fa-2x fa-spin fa-refresh"></i> Cargando clientes...
  </div>
  <div v-else>
    <model-select
      :options="clientes"
      v-model="cliente"
      placeholder="[Seleccione un cliente]"
    ></model-select>
    <input type="text" name="cliente" id="cliente" v-model="cliente" hidden />
  </div>
</template>
<script>
import { ModelSelect } from "vue-search-select";

export default {
  components: { ModelSelect },
  props: {
    urlclientes: {
      type: String,
      default: "/tabla/cliente",
    },
    efect: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getClientes();
  },
  data() {
    return {
      cliente: "",
      clientes: [{ text: "Seleccione", value: "", codigo: "" }],
    };
  },
  methods: {
    getClientes: function () {
      this.clientes = null;
      axios
        .post(this.urlclientes)
        .then((response) => {
          let data = [];
          if (this.efect == true) {
            data = $.map(response.data, function (obj) {
              obj.text =
                obj.value.replace(/v|j|g|e|-/gi, "") + " - " + obj.label;
              obj.value = obj.codigo;
              return obj;
            });
          } else {
            data = $.map(response.data, function (obj) {
              obj.text = obj.label;
              return obj;
            });
          }
          this.clientes = data;
        })
        .catch((e) => {
          $.notify(
            {
              icon: "fa fa-ban",
              title: "Error",
              message: e,
            },
            {
              element: "body",
              type: "danger",
              template:
                '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                '<h4><button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                '<span data-notify="icon"></span> ' +
                '<span data-notify="title">{1}</span></h4> ' +
                '<span data-notify="message">{2}</span>' +
                '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                "</div>" +
                '<a href="{3}" target="{4}" data-notify="url"></a>' +
                "</div>",
            }
          );
        });
    },
  },
};
</script>