export function getBoxCash() {
  this.cajas = null;
  axios
    .post(this.urlcajas)
    .then((response) => {
      this.cajas = response.data;
    })
    .catch((e) => {
      this.notificacion({
        icon: "fa fa-ban",
        title: "Error",
        e: e,
        type: "danger",
      });
    });
}

export function getBankAccount() {
  this.cuentas = null;
  axios
    .post(this.urlcuentas)
    .then((response) => {
      this.cuentas = response.data;
    })
    .catch((e) => {
      this.notificacion({
        icon: "fa fa-ban",
        title: "Error",
        e: e,
        type: "danger",
      });
    });
}
