import {
  AdjustPayment,
  CollectionsProps,
  getBankAccount,
  getBoxCash,
  getClients,
  getInvoices,
  PayBalance,
  PayPayment,
  PublishInvoice,
  SendInvoicePaid,
  SendNotification,
} from "./utils";

export default {
  props: CollectionsProps,
  data() {
    return {
      ocultaOverlay: true,
      msgCargando: "Cargando ...",
      cliente: { text: "", value: "", codigo: "" },
      clientes: [{ text: "Seleccione", value: "", codigo: "" }],
      facturas: [],
      cajas: [],
      cuentas: [],
      formaPag: "",
      totalfact: 0.0,
      diff: 0.0,
      ajustes: true,
      montoAb: 0.0,
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  },
  mounted() {
    this.getClientes();
    this.getCajas();
    this.getCuentas();
  },
  methods: {
    ajuste: AdjustPayment,
    abonar: PayPayment,
    cargarSaldo: PayBalance,
    notificacion: SendNotification,
    getClientes: getClients,
    getCajas: getBoxCash,
    getCuentas: getBankAccount,
    getFacturas: getInvoices,
    notificar: SendInvoicePaid,
    publicar: PublishInvoice,
    totalAb: function () {
      if (!this.facturas) return;
      let total = 0;
      for (let i = 0; i < this.facturas.length; i++) {
        total += parseFloat(this.facturas[i].montoAbonar);
        let saldop = this.facturas[i].saldo.replace(/\./g, "");
        if (
          parseFloat(saldop.replace(",", ".")) ===
            parseFloat(this.facturas[i].montoAbonar) ||
          parseFloat(this.facturas[i].montoAbonar) === 0
        ) {
          this.ajustes = this.ajustes && true;
        } else {
          this.ajustes = this.ajustes && false;
        }
      }
      this.montoAb = total.toFixed(2);
      this.totalfact = total.toFixed(2);
      this.ajustes =
        this.ajustes &&
        parseFloat(this.totalfact) < parseFloat(this.montoAb).toFixed(2);
      this.diff = (this.montoAb - this.totalfact).toFixed(2);

      if (
        parseFloat(this.totalfact) < parseFloat(this.montoAb).toFixed(2) &&
        this.formaPag != "1" &&
        this.formaPag != "" &&
        this.ajustes
      ) {
        $("#ajust").removeClass("hidden");
      } else {
        $("#ajust").addClass("hidden");
      }

      return parseFloat(this.montoAb).toFixed(2);
    }
  },
};
