export function AdjustPayment() {
  if (!this.facturas) return;
  console.log('pasa')
  let total = 0;
  this.ajustes = true;
  for (let i = 0; i < this.facturas.length; i++) {
    total += parseFloat(this.facturas[i].montoAbonar);
    let saldop = this.facturas[i].saldo.replace(/\./g, "");
    if (
      parseFloat(saldop.replace(",", ".")) ===
        parseFloat(this.facturas[i].montoAbonar) ||
      parseFloat(this.facturas[i].montoAbonar) === 0
    ) {
      this.ajustes = this.ajustes && true;
    } else {
      this.ajustes = this.ajustes && false;
    }
  }

  this.totalfact = total.toFixed(2);
  this.ajustes =
    this.ajustes &&
    parseFloat(this.totalfact) < parseFloat(this.montoAb).toFixed(2);
  this.diff = (this.montoAb - this.totalfact).toFixed(2);

  if (
    parseFloat(this.totalfact) < parseFloat(this.montoAb).toFixed(2) &&
    this.formaPag != "1" &&
    this.formaPag != "" &&
    this.ajustes
  ) {
    $("#ajust").removeClass("hidden");
  } else {
    $("#ajust").addClass("hidden");
  }
}

export function PayPayment(index, monto) {
  this.facturas[index].montoAbonar = parseFloat(monto);
}

export function PayBalance(index, monto) {
  monto = monto.replace(/\./g, "");
  this.facturas[index].montoAbonar = parseFloat(monto.replace(",", "."));
}
