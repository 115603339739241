export const CollectionsProps = {
  urlclientes: {
    type: String,
    default: "/tabla/cliente",
  },
  urlporcobrar: {
    type: String,
    default: "/reportes/facturas/cobrar/",
  },
  urlhome: {
    type: String,
    default: "/",
  },
  urlfacturas: {
    type: String,
    default: "/reportes/facturas",
  },
  urlstore: {
    type: String,
    default: "/reportes/facturas",
  },
  urlcajas: {
    type: String,
    default: "/tabla/cajas",
  },
  urlcuentas: {
    type: String,
    default: "/tabla/cuentas",
  },
  urlnotificar: {
    type: String,
    default: "/cobros/notificar",
  },
  fecha: "",
};
