<template>
    <div class="box box-default box-solid">
        <div class="box-body no-padding table-responsive">
            <div class="col-xs-12">
                <label for="cproduct">Código: </label>
                <div class="input-group">
                    <input class="form-control" id="cproduct"
                           type="text" placeholder="##########"
                           @keydown.enter="$event.preventDefault(); addArt(articuloBuscar);"
                           v-model="articuloBuscar"/>
                    <div class="input-group-btn">
                        <button class="btn btn-primary" type="button" @click="addArt(articuloBuscar); $event.preventDefault();"
                                v-model="articuloBuscar">Cargar</button>
                    </div>
                </div>
            </div>
            <table class="table table-striped table-hover">
                <thead>
                <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">Artículo</th>
                    <th class="text-center">Cantidad</th>
                    <th class="text-center">Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in rows" track-by="$index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td>
                        <select required
                                @change="valRow(index); artPrecioStock(index, $event.target)"
                                name="articulo[]"
                                class="form-control select2" v-model="row.articulo">
                            <option value="" selected>[Seleccione un art&iacute;culo]</option>
                            <option v-for="opt in articulos" :value="opt.value" :data-precio="opt.precio"
                                    :data-ref="opt.ref" :data-stock="opt.stock">{{ opt.label }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <div class="input-group">
                            <input class="form-control" type="number"
                                   v-bind:min="1" name="cantidad[]"
                                   required
                                   step="1"
                                   @focus="$event.target.select()"
                                   @change="valRow(index); totReng(index);"
                                   @keydown.enter="$event.preventDefault();"
                                   placeholder="0"
                                   v-model="row.cantidad"/>
                        </div>
                    </td>
                    <td class="text-center">
                        <button @click="removeRow(index)" type="button" class="btn btn-link text-red"><i
                                class="fa fa-2x fa-times"></i></button>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="5">
                        <button type="button" @click="addRow()" class="btn btn-flat btn-primary btn-block" :disabled="!agregar">
                            AGREGAR <i class="fa fa-list-ul"></i></button>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
        <div v-bind:class="{'hidden': ocultaOverlay, 'overlay': true}">
            <span><i class="fa fa-2x fa-spin fa-refresh"></i> {{ msgCargando }}</span>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            urlarticulos: {
                type: String,
                default: '/articulos/principal'
            },
            numorden: {
                default: ''
            },
            comentario: {
                default: ''
            },
            urlreng: {
                default: ''
            },
        },

        data() {
            return {
                ocultaOverlay: true,
                msgCargando: 'Cargando información...',
                agregar: true,
                adicional: this.comentario,
                articuloBuscar: '',
                articulos: {
                    label: "Seleccione",
                    value: "",
                    ref: "",
                    stock: 0,
                    precio: 0.00
                },
                rows: [
                    // {
                    //     articulo: "",
                    //     cantidad: null,
                    //     precio: 0.00,
                    //     totReng: 0.00,
                    //     cantMax: 0
                    // },
                ]
            }
        },
        mounted() {
            this.ocultaOverlay = false;
            axios.all([this.ordenRows(this.numorden),this.getArt()])
                .then(axios.spread(function (acct, perms) {
                }))
        },
        computed: {
            totalPedido: function () {
                if (!this.rows) return;
                let total = 0;
                for (let i = 0; i < this.rows.length; i++) {
                    total += this.rows[i].totReng;
                }
                return parseFloat(total).toFixed(2);
            },
        },
        methods: {
            formatoMonto(value) {
                let val = (value / 1).toFixed(2).replace('.', ',');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            notificacion: function (info){
                $.notify({
                    icon: info.icon,
                    title: info.title,
                    message: info.e,
                }, {
                    element: 'body',
                    type: info.type,
                    template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                        '<h4><button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                        '<span data-notify="icon"></span> ' +
                        '<span data-notify="title">{1}</span></h4> ' +
                        '<span data-notify="message">{2}</span>' +
                        '<div class="progress" data-notify="progressbar">' +
                        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                        '</div>' +
                        '<a href="{3}" target="{4}" data-notify="url"></a>' +
                        '</div>'
                });
            },
            getArt: function () {
                this.articulos = null;
                axios.post(this.urlarticulos)
                    .then(response => {
                        this.articulos = response.data;
                        this.ocultaOverlay = true;
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
            ordenRows: function (orden) {
                if(orden === '') return;
                axios.post(this.urlreng, {id: orden})
                    .then(response => {
                        this.rows = response.data;
                        this.agregar = true;
                        this.totalPedido;
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
            artPrecioStock: function (index, select) {
                let stock = $(select).find(":selected").data('stock');
                let precio = $(select).find(":selected").data('precio');
                if(this.rows[index].cantidad === (0 || null)) this.rows[index].cantidad = null;
                $(select).next().focus();
            },
            totReng: function (index) {
                this.rows[index].totReng = this.rows[index].precio * this.rows[index].cantidad;
            },
            valRow: function (index) {
                let a = this.rows[index].articulo;
                let rep = this.rows.filter(function (item) {
                    return (item.articulo === a);
                });
                let artExist = this.articulos.filter(function (item) {
                    return (item.value === a);
                });
                this.agregar = (rep.length === 1 && a !== "") && (this.rows[index].cantidad > 0);
                if (!(rep.length === 1)) {
                    let msj = "El artículo en el ítem #" + (index + 1) + " está repetido, seleccione otro.";
                    this.notificacion({icon: "fa fa-ban", title: "Advertencia", e: msj, type: "warning"});
                    this.rows[index].articulo = "";
                    this.rows[index].cantidad = null;
                }
            },
            addArt: function (articulo) {
                this.articuloBuscar = '';
                let rep = this.rows.filter(function (item) {
                    if(item.articulo === articulo || item.ref === articulo){
                        item.cantidad++;
                    }
                    return (item.articulo === articulo || item.ref === articulo);
                });
                if(rep.length === 0) {
                    let art = this.articulos.filter(function (item) {
                        return (item.value === articulo || item.ref === articulo) ? item : false;
                    });
                    if(art.length === 0){
                        let msj = "El artículo que intenta ingresar no existe.";
                        this.notificacion({icon: "fa fa-ban", title: "Advertencia", e: msj, type: "danger"});
                    }else {
                        art = JSON.parse(JSON.stringify(art));
                        this.rows.splice(this.rows.length + 1, 0, {
                            articulo: art[0].value,
                            ref: art[0].ref,
                            cantidad: 1,
                        });
                    }
                }
            },
            addRow: function () {
                try {
                    this.rows.splice(this.rows.length + 1, 0, {
                        articulo: "",
                        ref: "",
                        cantidad: null,
                    });
                    this.agregar = false;
                } catch (e) {
                    this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                }
            },
            removeRow: function (index) {
                this.rows.splice(index, 1);
                this.agregar = true;
            },
        }
    }
</script>