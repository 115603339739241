export function getClients() {
  this.clientes = null;
  axios
    .post(this.urlclientes)
    .then((response) => {
      let data = $.map(response.data, function(obj) {
        obj.text = obj.value.replace(/v|j|g|e|-/gi, "") + " - " + obj.label;
        return obj;
      });
      this.clientes = data;
      setTimeout(function() {
        let elements = document.getElementsByClassName("item");
        for (let i = 0; i < elements.length; i++) {
          let element = elements[i];
          element.style.fontSize = "1.5rem";
        }
      }, 800);
    })
    .catch((e) => {
      this.notificacion({
        icon: "fa fa-ban",
        title: "Error",
        e: e,
        type: "danger",
      });
    });
}

export function validateClient() {
  this.agregar = true;
  this.condicion = this.cliente.cond;
  for (let i = 0; i < this.rows.length; i++) {
    this.valRow(i);
    this.totRengBS(i);
    this.totRengDS(i);
  }
  axios
    .post(`${this.urlfacturas}/${this.cliente.codigo}`)
    .then((response) => {
      $("#btnOrderCreateSave").prop("disabled", false);
      let countOverdueBills = 0;
      if (response.data.length > 0) {
        $(".cliente").text(this.cliente.text);
        let dfacturas = "";
        response.data.map((item) => {
          if (!item.notificada) {
            if (item.diasEmis >= 7) {
              countOverdueBills++;
            }
            dfacturas += `
              <tr>
                  <td class="text-center">
                      <a href="#"
                          data-toggle="modal"
                          class="btn btn-flat btn-default btn-sm"
                          data-target="#mdInfo"
                          data-href="${this.urlshowfacturas}/show/${item.factNum}">
                          ${item.factNum}
                      </a>
                  </td>
                  <td class="text-center">${item.fecEmis}</td>
                  <td class="text-center">${item.fecVenc}</td>
                  <td class="text-center">${item.saldo}</td>
              </tr>`;
          } else {
            dfacturas += `
              <tr class="success">
                  <td class="text-center">
                      <a href="#"
                          data-toggle="modal"
                          class="btn btn-flat btn-default btn-sm"
                          data-target="#mdInfo"
                          data-href="${this.urlshowfacturas}/show/${item.factNum}">
                          ${item.factNum}
                      </a>
                  </td>
                  <td class="text-center">${item.fecEmis}</td>
                  <td class="text-center">${item.fecVenc}</td>
                  <td class="text-center">${item.saldo}</td>
              </tr>`;
          }
        });
        $(".facturas").html(dfacturas);
        $("#modalFacturas").modal("toggle");
      }
      $("#cerrar").addClass("hidden");
      let msj = "";
      if (this.vendedor == 1) {
        if (countOverdueBills == 1) {
          if (response.data[0].diasEmis >= 7) {
            msj = ` 
              <strong><i class="fa fa-warning text-red"></i>Usted está montando una Orden de Pedido a un cliente con al menos una factura vencida. ¿Desea continuar? </strong><br>`;
            $("#cerrar").removeClass("hidden");
          }
        }
        if (countOverdueBills >= 2) {
          msj = ` 
            <strong><i class="fa fa-warning text-red"></i>Usted está montando una Orden de Pedido a un cliente con al menos una factura vencida. ¿Desea continuar? </strong><br>`;
          $("#cerrar").removeClass("hidden");
        }
        $(".msj").html(msj);
      } else {
        if (countOverdueBills == 1) {
          if (response.data[0].diasEmis >= 7) {
            msj = ` 
              <strong><i class="fa fa-warning text-red"></i>No se puede generar pedido al cliente porque la factura tiene más de 6 días. Por favor contactar a su respectivo coordinador.</strong><br>`;
            $("#btnOrderCreateSave").prop("disabled", true);
            $(".modal-footer").html(
              `<a class="btn btn-flat btn-primary" href="${this.urlshowprod}">Aceptar</a>`
            );
            $("#close").removeAttr("data-dismiss");
            $("#close").prop("href", `${this.urlshowprod}`);
          }
        }
        if (countOverdueBills >= 2) {
          msj = ` 
            <strong><i class="fa fa-warning text-red"></i>No se puede generar pedido al cliente porque posee más de 1 factura. Por favor contactar a su respectivo coordinador.</strong><br>`;
          $("#btnOrderCreateSave").prop("disabled", true);
          $(".modal-footer").html(
            `<a class="btn btn-flat btn-primary" href="${this.urlshowprod}">Aceptar</a>`
          );
          $("#close").removeAttr("data-dismiss");
          $("#close").prop("href", `${this.urlshowprod}`);
        }
        $(".msj").html(msj);
      }
    })
    .catch((e) => {
      this.notificacion({
        icon: "fa fa-ban",
        title: "Error",
        e: e,
        type: "danger",
      });
    });
}
