<template>
  <div class="box box-default box-solid">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th class="text-center">#</th>
          <th class="text-center">&nbsp;</th>
          <th class="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in rows" :key="index" track-by="$index">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Artículo</label>
                  <model-list-select
                    :list="articulos"
                    v-model="row.articulo"
                    option-value="value"
                    :custom-text="formatoLabel"
                    placeholder="[Seleccione un artículo]"
                    name="mArticulo[]"
                    @input="valRow(index)"
                  >
                  </model-list-select>
                  <input
                    type="text"
                    name="articulo[]"
                    id="articulo[]"
                    v-model="row.articulo"
                    hidden
                  />
                  <a
                    v-if="row.articulo != ''"
                    href="#"
                    data-toggle="modal"
                    class="btn btn-flat btn-default btn-sm"
                    data-target="#mdInfo"
                    :data-id="index"
                    v-bind:data-href="
                      urlshowprod + '/inventario/show/' + row.articulo
                    "
                  >
                    <img
                      v-bind:id="'mproductImg_' + index"
                      v-bind:src="urlshowprod + '/img/productos' + row.imagen"
                      v-bind:alt="row.articulo"
                      width="25"
                      height="25"
                    />
                  </a>
                  <img
                    v-else
                    v-bind:src="urlshowprod + '/img/productos/logo.png'"
                    v-bind:alt="row.articulo"
                    width="35"
                    height="35"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="cantidad_$index">Cantidad</label>
                  <input
                    class="form-control"
                    type="number"
                    id="cantidad_$index"
                    v-bind:max="row.cantMax"
                    v-bind:min="0.5"
                    name="cantidad[]"
                    step="0.5"
                    lang="en"
                    required
                    @focus="$event.target.select()"
                    @change="
                      valRow(index);
                      totRengBS(index);
                      totRengDS(index);
                    "
                    @keydown.enter="$event.preventDefault()"
                    placeholder="0"
                    v-model="row.cantidad"
                  />
                  <span class="label label-default"
                    >max. {{ row.cantMax }}</span
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="precio_$index">Precio Bs</label>
                  {{ formatoMonto(row.precio) }}
                  <input
                    id="precio_$index"
                    type="text"
                    name="precio[]"
                    v-model="row.precio"
                    class="form-control hidden"
                    readonly
                  />
                </div>
                <div class="form-group">
                  <label for="precioDs_$index">Precio $</label>
                  {{ formatoMonto(row.precio_divisa) }}
                  <input
                    id="precioDs_$index"
                    type="text"
                    name="precioDs[]"
                    v-model="row.precio_divisa"
                    class="form-control hidden"
                    readonly
                  />
                </div>
              </div>
            </div>
          </td>
          <td class="text-center">
            <button
              @click="removeRow(index)"
              type="button"
              class="btn btn-link text-red"
            >
              <i class="fa fa-2x fa-times"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">
            <button
              type="button"
              @click="addRow()"
              class="btn btn-flat btn-primary btn-block"
              :disabled="!agregar"
            >
              AGREGAR <i class="fa fa-list-ul"></i>
            </button>
          </td>
        </tr>
        <tr>
          <th colspan="3">
            <h3>Total Bs: {{ formatoMonto(totalPedidoBS) }}</h3>
            <input
              type="text"
              name="totNeto"
              id="totNeto"
              v-model="totalPedidoBS"
              class="hidden"
            />
            <h3>Total $: {{ formatoMonto(totalPedidoDS) }}</h3>
            <input
              type="text"
              name="totNetoDs"
              id="totNetoDs"
              v-model="totalPedidoDS"
              class="hidden"
            />
          </th>
        </tr>
        <tr>
          <th colspan="3">
            <div class="form-group">
              <label for="comentario">Informaci&oacute;n Adicional</label>
              <textarea
                name="comentario"
                id="comentario"
                rows="4"
                class="form-control"
                placeholder=""
                maxlength="60"
                v-model="adicional"
              ></textarea>
            </div>
          </th>
        </tr>
      </tfoot>
    </table>
    <div v-bind:class="{ hidden: ocultaOverlay, overlay: true }">
      <span><i class="fa fa-2x fa-spin fa-refresh"></i> {{ msgCargando }}</span>
    </div>
  </div>
</template>
<script>
import { ModelListSelect } from "vue-search-select";
export default {
  components: { ModelListSelect },
  name: "pedidoOrdenReng",
  props: {
    urlarticulos: {
      type: String,
      default: "/tabla/articulo",
    },
    urlshowprod: {
      type: String,
      default: "/",
    },
    numorden: {
      default: "",
    },
    comentario: {
      default: "",
    },
    urlreng: {
      default: "",
    },
  },

  data() {
    return {
      ocultaOverlay: true,
      msgCargando: "Cargando información...",
      agregar: true,
      adicional: this.comentario,
      articulo: {},
      articulos: [],
      rows: [],
    };
  },
  mounted() {
    this.ocultaOverlay = false;
    axios.all([this.getArt()]).then(axios.spread(function (acct, perms) {}));
  },
  computed: {
    totalPedidoBS: function () {
      if (!this.rows) return;
      let total = 0;
      for (let i = 0; i < this.rows.length; i++) {
        total += this.rows[i].totRengBs;
      }
      return parseFloat(total).toFixed(2);
    },
    totalPedidoDS: function () {
      if (!this.rows) return;
      let total = 0;
      for (let i = 0; i < this.rows.length; i++) {
        total += this.rows[i].totRengDs;
      }
      return parseFloat(total).toFixed(2);
    },
  },
  methods: {
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatoLabel(item) {
      return `${item.value} - ${item.label}`;
    },
    notificacion: function (info) {
      $.notify(
        {
          icon: info.icon,
          title: info.title,
          message: info.e,
        },
        {
          element: "body",
          type: info.type,
          template:
            '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
            '<h4><button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
            '<span data-notify="icon"></span> ' +
            '<span data-notify="title">{1}</span></h4> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            "</div>" +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            "</div>",
        }
      );
    },
    getArt: function () {
      this.articulos = null;
      axios
        .post(this.urlarticulos)
        .then((response) => {
          this.articulos = response.data;
          this.ordenRows(this.numorden);
          this.ocultaOverlay = true;
        })
        .catch((e) => {
          this.notificacion({
            icon: "fa fa-ban",
            title: "Error",
            e: e,
            type: "danger",
          });
        });
    },
    ordenRows: function (orden) {
      if (orden === "") return;
      axios
        .post(this.urlreng, { id: orden })
        .then((response) => {
          this.rows = response.data;
          this.agregar = true;
          this.totalPedidoBS;
          this.totalPedidoDS;
          setTimeout(function () {
            let elements = document.getElementsByClassName("item");
            for (let i = 0; i < elements.length; i++) {
              let element = elements[i];
              element.style.fontSize = "1.5rem";
            }
          }, 800);
        })
        .catch((e) => {
          this.notificacion({
            icon: "fa fa-ban",
            title: "Error",
            e: e,
            type: "danger",
          });
        });
    },
    totRengBS: function (index) {
      this.rows[index].totRengBs =
        this.rows[index].precio * this.rows[index].cantidad;
    },
    totRengDS: function (index) {
      this.rows[index].totRengDs =
        this.rows[index].precio_divisa * this.rows[index].cantidad;
    },
    valRow: function (index) {
      this.articulo = this.articulos.filter(
        (item) => item.value === this.rows[index].articulo
      );
      if (this.articulo.length !== 0) {
        this.rows[index].nombre = this.articulo[0].label;
        this.rows[index].cantidad =
          this.rows[index].cantidad === 0 ? 0 : this.rows[index].cantidad;
        this.rows[index].totRengBs =
          this.rows[index].totRengBs === 0 ? 0 : this.rows[index].totRengBs;
        this.rows[index].totRengDs =
          this.rows[index].totRengDs === 0 ? 0 : this.rows[index].totRengDs;
        this.rows[index].cantMax = this.articulo[0].stock;
        this.rows[index].imagen = this.articulo[0].imagen;
        this.rows[index].precio =
          this.articulo[0].precio == null ? 0 : this.articulo[0].precio;
        this.rows[index].precio_divisa =
          this.articulo[0].precio_divisa == null
            ? 0
            : this.articulo[0].precio_divisa;
      } else {
        this.rows[index].nombre = "";
        this.rows[index].cantidad = 0;
        this.rows[index].totRengBs = 0;
        this.rows[index].totRengDs = 0;
        this.rows[index].cantMax = 0;
        this.rows[index].imagen = "/logo.png";
        this.rows[index].precio = 0;
        this.rows[index].precio_divisa = 0;
      }
      let a = this.rows[index].articulo;
      if (this.rows[index].cantidad > this.rows[index].cantMax) {
        this.rows[index].cantidad = null;
        let msj =
          "Superó la cantidad máxima del artículo en el ítem # " + (index + 1);
        this.notificacion({
          icon: "fa fa-ban",
          title: "Advertencia",
          e: msj,
          type: "warning",
        });
      }
      let rep = this.rows.filter(function (item) {
        return item.articulo === a;
      });
      this.agregar =
        rep.length === 1 && a !== "" && this.rows[index].cantidad > 0;
      if (!(rep.length === 1)) {
        this.rows[index].articulo = "";
        let msj =
          "El artículo en el ítem #" +
          (index + 1) +
          " está repetido, seleccione otro.";
        this.notificacion({
          icon: "fa fa-ban",
          title: "Advertencia",
          e: msj,
          type: "warning",
        });
      }
    },
    addRow: function () {
      try {
        this.rows.splice(this.rows.length + 1, 0, {
          articulo: "",
          cantidad: null,
          precio: 0.0,
          precio_divisa: 0.0,
          totRengBs: 0,
          totRengDs: 0,
          cantMax: 0,
          imagen: "/logo.png",
        });
        this.agregar = false;
        setTimeout(function () {
          let elements = document.getElementsByClassName("item");
          for (let i = 0; i < elements.length; i++) {
            let element = elements[i];
            element.style.fontSize = "1.5rem";
          }
        }, 800);
      } catch (e) {
        this.notificacion({
          icon: "fa fa-ban",
          title: "Error",
          e: e,
          type: "danger",
        });
      }
    },
    removeRow: function (index) {
      this.rows.splice(index, 1);
      this.agregar = true;
    },
  },
};
</script>