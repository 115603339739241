export function getVehicles() {
  this.transportes = null;
  axios
    .post(this.urltransportes)
    .then((response) => {
      this.transportes = response.data;
    })
    .catch((e) => {
      this.notificacion({
        icon: "fa fa-ban",
        title: "Error",
        e: e,
        type: "danger",
      });
    });
}
