<template>
    <div class="box box-default box-solid">
        <div class="box-body no-padding table-responsive">
            <div class="col-xs-12">
                <label for="cproduct">Código: </label>
                <div class="input-group">
                    <input class="form-control" id="cproduct"
                           type="text" placeholder="##########"
                           @keydown.enter="$event.preventDefault(); addArt(articuloBuscar);"
                           v-model="articuloBuscar"/>
                    <div class="input-group-btn">
                        <button class="btn btn-primary" type="button" @click="addArt(articuloBuscar); $event.preventDefault();"
                                v-model="articuloBuscar">Cargar</button>
                    </div>
                </div>
            </div>
            <table class="table table-striped table-hover">
                <thead>
                <tr>
                    <th class="text-center">#</th>
                    <th class="text-center" width="320px">Artículo</th>
                    <th class="text-center" width="80px">Impuesto</th>
                    <th class="text-center" width="95px">Cantidad</th>
                    <th class="text-center">Precio</th>
                    <th class="text-center" width="110px">%Desc.</th>
                    <th class="text-center" width="150px">Neto</th>
                    <th class="text-center">Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in rows" track-by="$index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td>
                        <select required
                                @change="valRow(index); artUltCost(index, $event.target)"
                                name="articulo[]"
                                class="form-control select2" v-model="row.articulo">
                            <option value="" selected>[Seleccione un art&iacute;culo]</option>
                            <option v-for="opt in articulos" :value="opt.value" :data-precio="opt.precio"
                                    :data-ref="opt.ref" :data-impuesto="opt.impuesto">
                                {{ opt.label }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <input class="form-control" name="impuesto[]"
                               required
                               @focus="$event.target.select()"
                               v-model="row.impuesto"/>
                    </td>
                    <td>
                        <input class="form-control" type="number"
                               v-bind:min="1" name="cantidad[]"
                               required
                               step="1"
                               @focus="$event.target.select()"
                               @change="valRow(index); totalReng(index);"
                               @keydown.enter="$event.preventDefault();"
                               placeholder="0"
                               v-model="row.cantidad"/>
                    </td>
                    <td class="text-right">
                        <input type="number" v-bind:min="1" step="0.01" name="precio[]"
                               required
                               @change="totalReng(index);"
                               v-model="row.precio" class="form-control"/>
                    </td>
                    <td class="text-right">
                        <input type="number" v-bind:min="0"  step="0.01" name="desc[]"
                               required
                               @change="totalReng(index);"
                               v-model="row.desc" class="form-control"/>
                    </td>
                    <td class="text-right">
                        <input type="number" step="0.01" name="neto[]" id="neto" v-model="row.totReng" class="form-control hidden" readonly/>
                        {{ formatoMonto(row.totReng) }}
                    </td>
                    <td class="text-center">
                        <button @click="removeRow(index)" type="button" class="btn btn-link text-red"><i
                                class="fa fa-2x fa-times"></i></button>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="8">
                        <button type="button" @click="addRow()" class="btn btn-flat btn-primary btn-block" :disabled="!agregar">
                            AGREGAR <i class="fa fa-list-ul"></i></button>
                    </td>
                </tr>
                <tr>
                    <th colspan="3"></th>
                    <th>
                        <div class="form-group">
                            <label for="descM">Tasa </label>
                            <input class="form-control" type="number" min="0.00" step="0.01"
                                   required name="tasa" id="tasa" v-model="tasa"/>
                        </div>
                    </th>
                    <th>
                        <div class="form-group">
                            <label for="descM">Desc. Monto </label>
                            <input class="form-control" type="number" min="0.00" step="0.01"
                                   required name="descM" id="descM"
                                   @change="totalDesP(); totalNeto();" v-model="montoDes"/>
                        </div>
                    </th>
                    <th>
                        <div class="form-group">
                            <label for="descP">Desc. % </label>
                            <input class="form-control" type="number"
                                   required min="0.00" step="0.01" name="descP" id="descP"
                                   @change="totalDesM(); totalNeto();" v-model="descPorc"/>
                        </div>
                    </th>
                    <th colspan="2" class="text-right">
                        <h4>Subtotal: {{ formatoMonto(totalOrden) }}</h4>
                        <input type="text" name="total" id="total" v-model="totalOrden" class="hidden" />
                        <h4>Descuento: {{ formatoMonto(totalDesM) }}</h4>
                        <input type="text" name="descuento" id="descuento" v-model="descMont" class="hidden" />
                        <h4>I.V.A: {{ formatoMonto(totalIva) }}</h4>
                        <input type="text" name="totIva" id="totIva" v-model="totalIva" class="hidden" />
                        <h4>Total: {{ formatoMonto(totalNeto) }}</h4>
                        <input type="text" name="totalNeto" id="totalNeto" v-model="totalNeto" class="hidden" />
                    </th>
                </tr>
                </tfoot>
            </table>
        </div>
        <div v-bind:class="{'hidden': ocultaOverlay, 'overlay': true}">
            <span><i class="fa fa-2x fa-spin fa-refresh"></i> {{ msgCargando }}</span>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'compraOrdenReng',
        props: {
            urlarticulos: {
                type: String,
                default: '/tabla/articulo'
            },
            numorden: {
                default: ''
            },
            comentario: {
                default: ''
            },
            porc: {
                default: 0.00
            },
            mont:  {
                default: 0.00
            },
            tasa:  {
                default: 0.00
            },
            urlreng: {
                default: ''
            },
        },

        data() {
            return {
                ocultaOverlay: true,
                msgCargando: 'Cargando información...',
                agregar: true,
                adicional: this.comentario,
                articuloBuscar: '',
                articulos: {
                    label: "Seleccione",
                    value: "",
                    ref: "",
                    precio: 0.00,
                    impuesto: "",
                },
                descPorc:  this.porc,
                descMont: this.mont,
                montoDes: this.mont,
                rows: [
                    // {
                    //     articulo: "",
                    //     cantidad: null,
                    //     precio: 0.00,
                    //     ref: "",
                    //     impuesto: "",
                    //     desc: 0.00,
                    //     totReng: 0.00
                    // },
                ]
            }
        },
        mounted() {
            this.ocultaOverlay = false;
            axios.all([this.getArt()])
                .then(axios.spread(function (acct, perms) {
                    this.agregar = true;
                }))
        },
        computed: {
            totalOrden: function () {
                if (!this.rows) return;
                let total = 0;
                for (let i = 0; i < this.rows.length; i++) {
                    total += parseFloat(this.rows[i].totReng);
                }
                return total;
            },
            totalDesP: function () {
                if (!this.rows) return;
                let total = 0;
                for (let i = 0; i < this.rows.length; i++) {
                    total += parseFloat(this.rows[i].totReng);
                }
                let totalm = this.montoDes * 100 / total;
                this.descMont = this.montoDes;
                this.descPorc = totalm;
                return this.descPorc;

            },
            totalDesM: function () {
                if (!this.rows) return;
                let total = 0;
                for (let i = 0; i < this.rows.length; i++) {
                    total += parseFloat(this.rows[i].totReng);
                }
                let totalm = total * this.descPorc / 100;
                this.montoDes = totalm.toFixed(2);
                this.descMont = totalm.toFixed(2);
                return this.descMont;
            },
            totalIva: function () {
                if (!this.rows) return;
                let total = 0;
                let iva = 0;
                for (let i = 0; i < this.rows.length; i++) {
                    // total += parseFloat(this.rows[i].totReng);
                    if(this.rows[i].impuesto == '6') continue;
                    if(this.rows[i].impuesto == '1')
                        iva += parseFloat(this.rows[i].totReng)*16/100;
                }
                // iva = total * 16 / 100;
                let totalm = iva * this.descPorc / 100;
                iva = iva - totalm;
                return iva;
            },
            totalNeto: function () {
                if (!this.rows) return;
                let total = 0;
                let iva = 0;
                for (let i = 0; i < this.rows.length; i++) {
                    total += parseFloat(this.rows[i].totReng);
                    if(this.rows[i].impuesto == '6') continue;
                    if(this.rows[i].impuesto == '1')
                        iva += parseFloat(this.rows[i].totReng)*16/100;
                }
                // iva = total * 16 / 100;
                iva = iva - iva * this.descPorc / 100;
                let totalm = total * this.descPorc / 100;
                let tneto = total + iva - totalm;
                return tneto;
            },
        },
        methods: {
            formatoMonto(value) {
                let val = (value / 1).toFixed(2).replace('.', ',');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            notificacion: function (info){
                $.notify({
                    icon: info.icon,
                    title: info.title,
                    message: info.e,
                }, {
                    element: 'body',
                    type: info.type,
                    template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                        '<h4><button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                        '<span data-notify="icon"></span> ' +
                        '<span data-notify="title">{1}</span></h4> ' +
                        '<span data-notify="message">{2}</span>' +
                        '<div class="progress" data-notify="progressbar">' +
                        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                        '</div>' +
                        '<a href="{3}" target="{4}" data-notify="url"></a>' +
                        '</div>'
                });
            },
            getArt: function () {
                this.articulos = null;
                axios.post(this.urlarticulos)
                    .then(response => {
                        this.articulos = response.data;
                        this.ordenRows(this.numorden);
                        this.ocultaOverlay = true;
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
            ordenRows: function (orden) {
                if(orden === '') return;
                axios.post(this.urlreng, {id: orden})
                    .then(response => {
                        this.rows = response.data;
                        this.agregar = true;
                        this.ocultaOverlay = true;
                        this.totalOrden;
                        this.totalIva;
                        this.totalNeto;
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
            totalReng: function (index) {
                let totalr = this.rows[index].precio * this.rows[index].cantidad - (this.rows[index].precio * this.rows[index].cantidad * this.rows[index].desc / 100);
                this.rows[index].totReng = parseFloat(totalr).toFixed(2);

            },
            valRow: function (index) {
                let a = this.rows[index].articulo;
                let rep = this.rows.filter(function (item) {
                    return item.articulo === a;
                });
                this.agregar = (rep.length === 1 && a !== " ") && (this.rows[index].cantidad > 0);
                if (!(rep.length === 1)) {
                    let msj = "El artículo en el ítem #" + (index + 1) + " está repetido, seleccione otro.";
                    this.notificacion({icon: "fa fa-ban", title: "Advertencia", e: msj, type: "warning"});
                    this.rows[index].articulo = "";
                    this.rows[index].cantidad = null;
                    this.rows[index].impuesto = "";
                }
            },
            artUltCost: function (index, select) {
                let precio = $(select).find(":selected").data('precio');
                let ref = $(select).find(":selected").data('ref');
                let impuesto = $(select).find(":selected").data('impuesto');
                if(precio == null){
                    this.rows[index].precio = 0.00;
                }else{
                    this.rows[index].precio = precio.toFixed(2);
                }
                this.rows[index].ref = ref;
                this.rows[index].impuesto = impuesto;
                this.rows[index].cantidad = null;
                this.rows[index].neto = 0.00;
                this.rows[index].totReng = 0.00;
                $(select).next().focus();
            },
            addArt: function (articulo) {
                this.articuloBuscar = '';
                let rep = this.rows.filter(function (item) {
                    if(item.articulo === articulo || item.ref === articulo){
                        item.cantidad++;
                        item.totReng = item.precio * item.cantidad - (item.precio * item.cantidad * item.desc/ 100);
                    }
                    return (item.articulo === articulo || item.ref === articulo);
                });
                if(rep.length === 0) {
                    let art = this.articulos.filter(function (item) {
                        return (item.value === articulo || item.ref === articulo) ? item : false;
                    });
                    if(art.length === 0){
                        let msj = "El artículo que intenta ingresar no existe.";
                        this.notificacion({icon: "fa fa-ban", title: "Advertencia", e: msj, type: "danger"});
                    }else {
                        art = JSON.parse(JSON.stringify(art));
                        this.rows.splice(this.rows.length + 1, 0, {
                            articulo: art[0].value,
                            ref: art[0].ref,
                            cantidad: 1,
                            precio: art[0].precio,
                            impuesto: art[0].impuesto,
                            desc: 0.00,
                            neto: 0.00,
                            totReng: art[0].precio
                        });
                    }
                }
            },
            addRow: function () {
                try {
                    this.rows.splice(this.rows.length + 1, 0, {
                        articulo: "",
                        ref: "",
                        cantidad: null,
                        precio: 0.00,
                        impuesto: "",
                        desc: 0.00,
                        neto: 0.00,
                        totReng: 0.00
                    });
                    this.agregar = false;
                } catch (e) {
                    this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                }
            },
            removeRow: function (index) {
                this.rows.splice(index, 1);
                this.agregar = true;
            },
        }
    }
</script>