<template>
    <div class="box box-default box-solid">
        <form id="form" v-bind:action="urlstore" method="post">
            <div class="box-body">
                <input type="hidden" name="_token" :value="csrf"/>
                <div class="form-group">
                    <div class="col col-sm-4">
                        <label for="codigo">Código</label>
                        <input id="codigo" name="codigo" type="text" class="form-control"
                               :value="this.codigo" readonly required>
                    </div>
                    <div class="col col-sm-4">
                        <label for="nombre">Nombre</label>
                        <input id="nombre" name="nombre" type="text" class="form-control" required>
                    </div>
                    <div class="col col-sm-4">
                        <label>Línea</label>
                        <div v-if="lineas">
                            <model-select :options="lineas" v-model="linea" @input="sublLineas();"
                                          placeholder="[Seleccione una línea]"></model-select>
                            <input type="text" name="linea" id="linea" v-model="linea.value" hidden/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col col-sm-4">
                        <br><label>Sublínea</label>
                        <div v-if="sublineas">
                            <model-select :options="sublineas" v-model="sublinea"
                                          placeholder="[Seleccione una sublínea]"></model-select>
                            <input type="text" name="sublinea" id="sublinea" v-model="sublinea.value" hidden/>
                        </div>
                    </div>
                    <div class="col col-sm-4">
                        <br><label>Color</label>
                        <div v-if="colores">
                            <model-select :options="colores" v-model="color"
                                          placeholder="[Seleccione un color]"></model-select>
                            <input type="text" name="color" id="color" v-model="color.value" hidden/>
                        </div>
                    </div>
                    <div class="col col-sm-4">
                        <br><label>Procedencia</label>
                        <div v-if="procedencias">
                            <model-select :options="procedencias" v-model="procedencia"
                                          placeholder="[Seleccione una procedencia]"></model-select>
                            <input type="text" name="procedencia" id="procedencia" v-model="procedencia.value" hidden/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col col-sm-4">
                        <br><label>I.V.A.</label>
                        <div v-if="impuestos">
                            <model-select :options="impuestos" v-model="impuesto"
                                          placeholder="[Seleccione un impuesto]"></model-select>
                            <input type="text" name="impuesto" id="impuesto" v-model="impuesto.value" hidden/>
                        </div>
                    </div>
                    <div class="col col-sm-4">
                        <br><label>Unidad Primaria</label>
                        <div v-if="unidades">
                            <model-select :options="unidades" v-model="unidadp"
                                          placeholder="[Seleccione la unidad primaria]"></model-select>
                            <input type="text" name="uprimaria" id="uprimaria" v-model="unidadp.value" hidden/>
                        </div>
                    </div>
                    <div class="col col-sm-4">
                        <br><label>Unidad Secundaria</label>
                        <div v-if="unidades">
                            <model-select :options="unidades" v-model="unidads"
                                          placeholder="[Seleccione la unidad secundaria]"></model-select>
                            <input type="text" name="usecundaria" id="usecundaria" v-model="unidads.value" hidden/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col col-sm-6">
                        <br><label>Proveedor</label>
                        <div v-if="proveedores">
                            <model-select :options="proveedores" v-model="proveedor"
                                          placeholder="[Seleccione un proveedor]"></model-select>
                            <input type="text" name="proveedor" id="proveedor" v-model="proveedor.codigo" hidden/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-bind:class="{'hidden': ocultaOverlay, 'overlay': true}">
                <span><i class="fa fa-2x fa-spin fa-refresh"></i> {{ msgCargando }}</span>
            </div>
            <div class="box-footer">
                <a class="btn btn-flat btn-default" v-bind:href="urlhome"><i class="fa fa-arrow-left"></i> Volver</a>
                <button v-if="!this.linea.value || !this.sublinea.value || !this.color.value || !this.proveedor.value
                || !this.procedencia.value || !this.impuesto.value || !this.unidadp.value || !this.unidads.value"
                        type="submit" class="btn btn-flat btn-primary" disabled>
                    <i class="fa fa-save"> </i> Guardar
                </button>
                <button v-else type="submit" class="btn btn-flat btn-primary btnGuardar" data-loading-text="Guardando...">
                    <i class="fa fa-save"> </i> Guardar
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import { ModelSelect } from 'vue-search-select';

    export default {
        components: {ModelSelect},
        props: {
            urllineas: {
                type: String,
                default: '/tabla/lineas'
            },
            urlsublineas: {
                type: String,
                default: '/tabla/sublineas'
            },
            urlhome: {
                type: String,
                default: '/'
            },
            urlcolores: {
                type: String,
                default: '/tabla/colores'
            },
            urlstore: {
                type: String,
                default: '/articulos'
            },
            urlimpuestos: {
                type: String,
                default: '/tabla/impuestos'
            },
            urlproveedores: {
                type: String,
                default: '/tabla/proveedores'
            },
            urlprocedencias: {
                type: String,
                default: '/tabla/procedencias'
            },
            urlunidades: {
                type: String,
                default: '/tabla/unidades'
            },
            codigo: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                ocultaOverlay: true,
                msgCargando: 'Cargando ...',
                proveedor: {text: "", value: "", codigo: ""},
                proveedores: [{text: "Seleccione", value: "", codigo: ""}],
                linea: {text: "", value: ""},
                lineas: [{text: "Seleccione", value: ""}],
                sublinea: {text: "", value: ""},
                sublineas: [{text: "Seleccione", value: ""}],
                color: {text: "", value: ""},
                colores: [{text: "Seleccione", value: ""}],
                impuesto: {text: "", value: ""},
                impuestos: [{text: "Seleccione", value: ""}],
                procedencia: {text: "", value: ""},
                procedencias: [{text: "Seleccione", value: ""}],
                unidadp: {text: "", value: ""},
                unidads: {text: "", value: ""},
                unidades: [{text: "Seleccione", value: ""}],
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        mounted() {
            this.getProveedores();
            this.getLineas();
            this.getSublineas();
            this.getColores();
            this.getProcedencias();
            this.getImpuestos();
            this.getUnidades();
        },
        computed: {
            //
        },
        methods: {
            notificacion: function (info){
                $.notify({
                    icon: info.icon,
                    title: info.title,
                    message: info.e,
                }, {
                    element: 'body',
                    type: info.type,
                    template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                        '<h4><button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                        '<span data-notify="icon"></span> ' +
                        '<span data-notify="title">{1}</span></h4> ' +
                        '<span data-notify="message">{2}</span>' +
                        '<div class="progress" data-notify="progressbar">' +
                        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                        '</div>' +
                        '<a href="{3}" target="{4}" data-notify="url"></a>' +
                        '</div>'
                });
            },
            incrFuenteSelect: function () {
                setTimeout(function() {
                    let elements = document.getElementsByClassName('item');
                    for (let i = 0; i < elements.length; i++) {
                        let element = elements[i];
                        element.style.fontSize = "1.5rem";
                    }
                }, 800);
            },
            getProveedores: function () {
                this.proveedores = null;
                axios.post(this.urlproveedores)
                    .then(response => {
                        let data = $.map(response.data, function (obj) {
                            obj.text = obj.value.replace(/v|j|g|e|V|J|G|E|-/gi, '') + " - " + obj.label;
                            return obj;
                        });
                        this.proveedores = data;
                        this.incrFuenteSelect();
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
            getLineas: function () {
                this.lineas = null;
                axios.post(this.urllineas)
                    .then(response => {
                        let data = $.map(response.data, function (obj) {
                            obj.text = obj.value + " - " + obj.label;
                            return obj;
                        });
                        this.lineas = data;
                        this.incrFuenteSelect();
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
            getSublineas: function () {
                this.sublineas = null;
                axios.post(this.urlsublineas)
                    .then(response => {
                        let data = $.map(response.data, function (obj) {
                            obj.text = obj.value + " - " + obj.label;
                            return obj;
                        });
                        this.sublineas = data;
                        this.incrFuenteSelect();
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
            getColores: function () {
                this.colores = null;
                axios.post(this.urlcolores)
                    .then(response => {
                        let data = $.map(response.data, function (obj) {
                            obj.text = obj.value + " - " + obj.label;
                            return obj;
                        });
                        this.colores = data;
                        this.incrFuenteSelect();
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
            getProcedencias: function () {
                this.procedencias = null;
                axios.post(this.urlprocedencias)
                    .then(response => {
                        let data = $.map(response.data, function (obj) {
                            obj.text = obj.value + " - " + obj.label;
                            return obj;
                        });
                        this.procedencias = data;
                        this.incrFuenteSelect();
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
            getImpuestos: function () {
                this.impuestos = null;
                axios.post(this.urlimpuestos)
                    .then(response => {
                        let data = $.map(response.data, function (obj) {
                            obj.text = obj.value + " - " + obj.label;
                            return obj;
                        });
                        this.impuestos = data;
                        this.incrFuenteSelect();
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
            getUnidades: function () {
                this.unidades = null;
                axios.post(this.urlunidades)
                    .then(response => {
                        let data = $.map(response.data, function (obj) {
                            obj.text = obj.value + " - " + obj.label;
                            return obj;
                        });
                        this.unidades = data;
                        this.incrFuenteSelect();
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
            sublLineas: function () {
                this.sublineas = null;
                axios.post(this.urlsublineas + '/' + this.linea.value)
                    .then(response => {
                        let data = $.map(response.data, function (obj) {
                            obj.text = obj.value + " - " + obj.label;
                            return obj;
                        });
                        this.sublineas = data;
                        this.incrFuenteSelect();
                    })
                    .catch(e => {
                        this.notificacion({icon: "fa fa-ban", title: "Error", e: e, type: "danger"});
                    });
            },
        }
    }
</script>