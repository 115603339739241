<template>
  <div v-if="!almacenes">
    <i class="fa fa-2x fa-spin fa-refresh"></i> Cargando almacenes...
  </div>
  <select
    v-else
    class="form-control select2"
    name="almacen"
    id="almacen"
    v-model="almacen"
    required
  >
    <option value="" selected>[Seleccione un almacen]</option>
    <option v-for="opt in almacenes" :value="opt.value" :key="opt.value">
      {{ opt.label }}
    </option>
  </select>
</template>
<script>
export default {
  props: {
    urlalmacenes: {
      type: String,
      default: "/tabla/almacenes",
    },
  },
  mounted() {
    this.getAlmacenes();
  },
  data() {
    return {
      almacen: "",
      almacenes: [{ label: "Seleccione", value: "" }],
    };
  },
  methods: {
    getAlmacenes: function () {
      this.almacenes = null;
      axios
        .post(this.urlalmacenes)
        .then((response) => {
          this.almacenes = response.data;
        })
        .catch((e) => {
          $.notify(
            {
              icon: "fa fa-ban",
              title: "Error",
              message: e,
            },
            {
              element: "body",
              type: "danger",
              template:
                '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                '<h4><button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                '<span data-notify="icon"></span> ' +
                '<span data-notify="title">{1}</span></h4> ' +
                '<span data-notify="message">{2}</span>' +
                '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                "</div>" +
                '<a href="{3}" target="{4}" data-notify="url"></a>' +
                "</div>",
            }
          );
        });
    },
  },
};
</script>