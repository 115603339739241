<template>
  <div v-if="!vendedores">
    <i class="fa fa-2x fa-spin fa-refresh"></i> Cargando vendedores...
  </div>
  <div v-else>
    <model-select
      :options="vendedores"
      v-model="vendedor"
      placeholder="[Seleccione un vendedor]"
    ></model-select>
    <input
      type="text"
      name="vendedor"
      id="vendedor"
      v-model="vendedor"
      hidden
    />
  </div>
</template>
<script>
import { ModelSelect } from "vue-search-select";
export default {
  components: { ModelSelect },
  props: {
    urlvendedores: {
      type: String,
      default: "/tabla/vendedor",
    },
  },
  mounted() {
    this.getVendedores();
  },
  data() {
    return {
      vendedor: "",
      vendedores: [{ text: "Seleccione", value: "" }],
    };
  },
  methods: {
    getVendedores: function () {
      this.vendedores = null;
      axios
        .post(this.urlvendedores)
        .then((response) => {
          let data = $.map(response.data, function (obj) {
            obj.text = obj.label;
            return obj;
          });
          this.vendedores = data;
        })
        .catch((e) => {
          $.notify(
            {
              icon: "fa fa-ban",
              title: "Error",
              message: e,
            },
            {
              element: "body",
              type: "danger",
              template:
                '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                '<h4><button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                '<span data-notify="icon"></span> ' +
                '<span data-notify="title">{1}</span></h4> ' +
                '<span data-notify="message">{2}</span>' +
                '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                "</div>" +
                '<a href="{3}" target="{4}" data-notify="url"></a>' +
                "</div>",
            }
          );
        });
    },
  },
};
</script>