export function getInvoices() {
  $("#campos").addClass("hidden");
  this.ocultaOverlay = false;
  this.facturas = null;
  this.formaPag = "";
  this.montoAb = 0.0;
  if (!this.cliente.codigo) {
    this.notificacion({
      icon: "fa fa-warning",
      title: "Error",
      e: "Seleccione un cliente",
      type: "warning",
    });
    this.ocultaOverlay = true;
  } else {
    axios
      .get(this.urlporcobrar + "/" + this.cliente.codigo)
      .then((response) => {
        this.facturas = response.data;
        this.ocultaOverlay = true;
        if (this.facturas.length > 0) {
          $("#campos").removeClass("hidden");
        }
      })
      .catch((e) => {
        this.notificacion({
          icon: "fa fa-ban",
          title: "Error",
          e: e,
          type: "danger",
        });
      });
  }
}

export function SendInvoicePaid(numf, index) {
  this.ocultaOverlay = false;
  axios
    .post(this.urlnotificar, { fact: numf })
    .then((response) => {
      this.facturas[index].notificada = !this.facturas[index].notificada;
      let msj = "";
      let tipo = "";
      if (this.facturas[index].notificada == false) {
        msj = "Factura Desmarcada";
        tipo = "info";
      } else {
        msj = "Factura Marcada";
        tipo = "success";
      }
      this.ocultaOverlay = true;
      this.notificacion({
        icon: "fa fa-check",
        title: "Bien",
        e: msj,
        type: tipo,
      });
    })
    .catch((e) => {
      this.notificacion({
        icon: "fa fa-ban",
        title: "Error",
        e: e,
        type: "danger",
      });
    });
}

export function PublishInvoice(numf, index) {
  this.ocultaOverlay = false;
  axios
    .post(this.urlfacturas + "/publicar/" + numf + "/n")
    .then(() => {
      this.facturas[index].publicada = !this.facturas[index].publicada;
      let msj = "";
      let tipo = "";
      if (this.facturas[index].publicada == false) {
        msj = "Factura Desactivada";
        tipo = "info";
      } else {
        msj = "Factura Activada";
        tipo = "success";
      }
      this.ocultaOverlay = true;
      this.notificacion({
        icon: "fa fa-check",
        title: "Bien",
        e: msj,
        type: tipo,
      });
    })
    .catch((e) => {
      this.notificacion({
        icon: "fa fa-ban",
        title: "Error",
        e: e,
        type: "danger",
      });
    });
}
