import {
  addRow,
  CustomerOrderProps,
  getClients,
  getOrderRows,
  getVehicles,
  totalPedidoBS,
  totalPedidoDS,
  totRengBS,
  totRengDS,
  validateClient,
  validateRow,
  getProducts,
  removeRow,
  sendNotification,
  getSelected,
  amountFormat,
  formatLabel,
} from "./utils";

export default {
  props: CustomerOrderProps,
  data() {
    return {
      ocultaOverlay: true,
      msgCargando: "Cargando información...",
      adicional: this.comentario,
      agregar: false,
      condicion: this.condcli,
      articulo: {},
      articulos: [],
      cliente: { text: "", value: "", codigo: "", cond: "" },
      clientes: [{ text: "Seleccione", value: "", codigo: "", cond: "" }],
      transportes: [],
      rows: [],
    };
  },
  mounted: function () {
    this.ocultaOverlay = false;
    if (this.pedido == true) {
      this.getTransportes();
    } else {
      this.getClientes();
    }
    axios.all([this.getArt()]).then();
  },
  computed: {
    totalPedidoBS,
    totalPedidoDS,
  },
  methods: {
    seleccionados: getSelected,
    formatoMonto: amountFormat,
    formatoLabel: formatLabel,
    notificacion: sendNotification,
    getClientes: getClients,
    getTransportes: getVehicles,
    valCli: validateClient,
    getArt: getProducts,
    ordenRows: getOrderRows,
    totRengBS,
    totRengDS,
    valRow: validateRow,
    addRow,
    removeRow,
  },
}