<template>
  <div class="box-body">
    <div v-if="numorden != ''" class="box-body">
      <div class="box box-default box-solid">
        <div class="col-md-7">
          <div class="form-group">
            <h3>Cliente</h3>
            <label class="dclient control-label">Nombre o Razon:</label>
            {{ nombrecli }}
            <br />
            <label class="dclient control-label">RIF:</label> {{ rifcli }}
            <button
              v-if="pedido == false"
              class="btn btn-primary btn-sm btn-flat"
              type="button"
              data-toggle="collapse"
              data-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
              style="margin-left: 15px"
            >
              Cambiar
            </button>
          </div>
          <div v-if="pedido != false" class="form-group">
            <label class="control-label">Transporte:</label>
            <div v-if="!transportes">
              <i class="fa fa-2x fa-spin fa-refresh"></i> Cargando
              transportes...
            </div>
            <select
              v-else
              class="form-control"
              name="transporte"
              id="transporte"
              required
            >
              <option value="" selected>[Seleccione un transporte]</option>
              <option v-for="opt in transportes" :value="opt.value" :key="opt.value">
                {{ opt.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-5">
          <div v-if="pedido == false" class="form-group pull-right">
            <h3>
              Orden de Pedido #<span class="text-red"> {{ numorden }}</span>
            </h3>
          </div>
          <div v-else class="form-group pull-right">
            <h3>
              Orden #<span class="text-red"> {{ numorden }}</span>
            </h3>
            <label>Vendedor</label> {{ nombreven }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="pedido == false" class="form-group">
      <div v-if="numorden != ''" class="collapse" id="collapseExample">
        <div class="row">
          <div class="col-md-9">
            <label class="control-label" for="cliente">Cliente</label>
            <div v-if="!clientes">
              <i class="fa fa-2x fa-spin fa-refresh"></i> Cargando clientes...
            </div>
            <div v-else>
              <model-select
                :options="clientes"
                v-model="cliente"
                placeholder="[Seleccione un cliente]"
                @input="valCli()"
              ></model-select>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-md-9">
          <label class="control-label" for="cliente">Cliente</label>
          <div v-if="!clientes">
            <i class="fa fa-2x fa-spin fa-refresh"></i> Cargando clientes...
          </div>
          <div v-else>
            <model-select
              :options="clientes"
              v-model="cliente"
              placeholder="[Seleccione un cliente]"
              @input="valCli()"
            ></model-select>
          </div>
        </div>
        <div v-if="numorden == ''" class="col-md-3">
          <label class="control-label" for="fecha">Fecha</label>
          <input
            class="form-control datepicker"
            readonly
            type="text"
            name="fecha"
            id="fecha"
            :value="fecha"
          />
        </div>
      </div>
      <input
        type="text"
        name="cliente"
        id="cliente"
        v-model="cliente.value"
        hidden
      />
    </div>
    <div class="form-group">
      <div class="table-responsive">
        <div class="box box-default box-solid">
          <div class="box-body no-padding table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th class="text-center">#</th>
                  <th class="text-center">Imagen</th>
                  <th class="text-center">Articulo</th>
                  <th class="text-center" width="250px">Cantidad</th>
                  <th class="text-center">Precio</th>
                  <th class="text-center">Precio $</th>
                  <th class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in rows" :key="index" track-by="$index">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center" v-if="row.articulo != ''">
                    <a
                      href="#"
                      data-toggle="modal"
                      class="btn btn-flat btn-default btn-sm"
                      data-target="#mdInfo"
                      :data-id="index"
                      v-bind:data-href="
                        urlshowprod + '/inventario/show/' + row.articulo
                      "
                    >
                      <img
                        v-bind:id="'mproductImg_' + index"
                        v-bind:src="urlshowprod + '/img/productos' + row.imagen"
                        v-bind:alt="row.articulo"
                        width="25"
                        height="25"
                      />
                    </a>
                  </td>
                  <td class="text-center" v-else>
                    <img
                      v-bind:src="urlshowprod + '/img/productos/logo.png'"
                      v-bind:alt="row.articulo"
                      width="35"
                      height="35"
                    />
                  </td>
                  <td>
                    <model-list-select
                      :list="articulos"
                      v-model="row.articulo"
                      option-value="value"
                      :custom-text="formatoLabel"
                      placeholder="[Seleccione un artículo]"
                      name="mArticulo[]"
                      @input="valRow(index)"
                    >
                    </model-list-select>
                    <input
                      type="text"
                      name="articulo[]"
                      id="articulo[]"
                      v-model="row.articulo"
                      hidden
                    />
                  </td>
                  <td>
                    <div class="input-group">
                      <input
                        class="form-control"
                        type="number"
                        v-bind:max="row.cantMax"
                        v-bind:min="0.5"
                        name="cantidad[]"
                        step="0.5"
                        lang="en"
                        required
                        @change="
                          valRow(index);
                          totRengBS(index);
                          totRengDS(index);
                        "
                        @keydown.enter="$event.preventDefault()"
                        placeholder="0"
                        v-model="row.cantidad"
                      />
                      <span class="input-group-addon"
                        >max. {{ row.cantMax }}</span
                      >
                    </div>
                  </td>
                  <td class="text-right">
                    {{ formatoMonto(row.precio) }}
                    <input
                      type="text"
                      name="precio[]"
                      v-model="row.precio"
                      class="form-control hidden"
                      readonly
                    />
                  </td>
                  <td class="text-right">
                    {{ formatoMonto(row.precio_divisa) }}
                    <input
                      type="text"
                      name="precioDs[]"
                      v-model="row.precio_divisa"
                      class="form-control hidden"
                      readonly
                    />
                  </td>
                  <td class="text-center">
                    <button
                      @click="removeRow(index)"
                      type="button"
                      class="btn btn-link text-red"
                    >
                      <i class="fa fa-2x fa-times"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="7">
                    <button
                      type="button"
                      @click="addRow()"
                      class="btn btn-flat btn-primary btn-block"
                      :disabled="!agregar"
                    >
                      AGREGAR <i class="fa fa-list-ul"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <th colspan="4">
                    <div class="form-group">
                      <label for="comentario"
                        >Informaci&oacute;n Adicional</label
                      >
                      <textarea
                        name="comentario"
                        id="comentario"
                        rows="4"
                        class="form-control"
                        placeholder=""
                        maxlength="60"
                        v-model="adicional"
                      ></textarea>
                    </div>
                  </th>
                  <th colspan="3">
                    <h3>Total BS: {{ formatoMonto(totalPedidoBS) }}</h3>
                    <input
                      type="text"
                      name="totNeto"
                      id="totNeto"
                      v-model="totalPedidoBS"
                      class="hidden"
                    />
                    <h3>Total $: {{ formatoMonto(totalPedidoDS) }}</h3>
                    <input
                      type="text"
                      name="totNetoDs"
                      id="totNetoDs"
                      v-model="totalPedidoDS"
                      class="hidden"
                    />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div v-bind:class="{ hidden: ocultaOverlay, overlay: true }">
            <span
              ><i class="fa fa-2x fa-spin fa-refresh"></i>
              {{ msgCargando }}</span
            >
          </div>
        </div>
      </div>
      <p class="text-danger">Los montos no incluyen el IVA</p>
    </div>
  </div>
</template>
<script>
import { ModelListSelect, ModelSelect } from "vue-search-select";
export default {
  components: { ModelListSelect, ModelSelect },
  props: {
    urlclientes: {
      type: String,
      default: "/tabla/cliente",
    },
    urlarticulos: {
      type: String,
      default: "/tabla/articulo",
    },
    urlfacturas: {
      type: String,
      default: "/facturas/cobrar",
    },
    urlshowfacturas: {
      type: String,
      default: "/facturas",
    },
    urltransportes: {
      type: String,
      default: "/tabla/transportes",
    },
    urlshowprod: {
      type: String,
      default: "/",
    },
    numorden: {
      default: "",
    },
    condcli: {
      default: "",
    },
    nombrecli: {
      default: "",
    },
    nombreven: {
      default: "",
    },
    rifcli: {
      default: "",
    },
    pedido: {
      type: Boolean,
      default: false,
    },
    fecha: "",
    comentario: {
      default: "",
    },
    urlreng: {
      default: "",
    },
    vendedor: {
      default: 1,
    },
  },
  data() {
    return {
      ocultaOverlay: true,
      msgCargando: "Cargando información...",
      adicional: this.comentario,
      agregar: false,
      condicion: this.condcli,
      articulo: {},
      articulos: [],
      cliente: { text: "", value: "", codigo: "", cond: "" },
      clientes: [{ text: "Seleccione", value: "", codigo: "", cond: "" }],
      transportes: [],
      rows: [],
    };
  },
  mounted: function () {
    this.ocultaOverlay = false;
    if (this.pedido == true) {
      this.getTransportes();
    } else {
      this.getClientes();
    }
    axios.all([this.getArt()]).then(axios.spread(function (acct, perms) {}));
  },
  computed: {
    totalPedidoBS: function () {
      if (!this.rows) return;
      let total = 0;
      for (let i = 0; i < this.rows.length; i++) {
        total += this.rows[i].totRengBs;
      }
      return parseFloat(total).toFixed(2);
    },
    totalPedidoDS: function () {
      if (!this.rows) return;
      let total = 0;
      for (let i = 0; i < this.rows.length; i++) {
        total += this.rows[i].totRengDs;
      }
      return parseFloat(total).toFixed(2);
    },
  },
  methods: {
    seleccionados: function () {
      let art = [];
      for (let i = 0; i < this.rows.length; i++) {
        art.push(this.rows[i].articulo);
      }
      return art;
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatoLabel(item) {
      return `${item.value} - ${item.label}`;
    },
    notificacion: function (info) {
      $.notify(
        {
          icon: info.icon,
          title: info.title,
          message: info.e,
        },
        {
          element: "body",
          type: info.type,
          template:
            '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
            '<h4><button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
            '<span data-notify="icon"></span> ' +
            '<span data-notify="title">{1}</span></h4> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            "</div>" +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            "</div>",
        }
      );
    },
    getClientes: function () {
      this.clientes = null;
      axios
        .post(this.urlclientes)
        .then((response) => {
          let data = $.map(response.data, function (obj) {
            obj.text = obj.value.replace(/v|j|g|e|-/gi, "") + " - " + obj.label;
            return obj;
          });
          this.clientes = data;
          setTimeout(function () {
            let elements = document.getElementsByClassName("item");
            for (let i = 0; i < elements.length; i++) {
              let element = elements[i];
              element.style.fontSize = "1.5rem";
            }
          }, 800);
        })
        .catch((e) => {
          this.notificacion({
            icon: "fa fa-ban",
            title: "Error",
            e: e,
            type: "danger",
          });
        });
    },
    getTransportes: function () {
      this.transportes = null;
      axios
        .post(this.urltransportes)
        .then((response) => {
          this.transportes = response.data;
        })
        .catch((e) => {
          this.notificacion({
            icon: "fa fa-ban",
            title: "Error",
            e: e,
            type: "danger",
          });
        });
    },
    valCli: function () {
      this.agregar = true;
      this.condicion = this.cliente.cond;
      for (let i = 0; i < this.rows.length; i++) {
        this.valRow(i);
        this.totRengBS(i);
        this.totRengDS(i);
      }
      axios
        .post(`${this.urlfacturas}/${this.cliente.codigo}`)
        .then((response) => {
          $("#btnOrderCreateSave").prop("disabled", false);
          let countOverdueBills = 0;
          if (response.data.length > 0) {
            $(".cliente").text(this.cliente.text);
            let dfacturas = "";
            response.data.map((item) => {
              if (!item.notificada) {
                if (item.diasEmis >= 7) {
                  countOverdueBills++;
                }
                dfacturas += `
									<tr>
											<td class="text-center">
													<a href="#"
															data-toggle="modal"
															class="btn btn-flat btn-default btn-sm"
															data-target="#mdInfo"
															data-href="${this.urlshowfacturas}/show/${item.factNum}">
															${item.factNum}
													</a>
											</td>
											<td class="text-center">${item.fecEmis}</td>
											<td class="text-center">${item.fecVenc}</td>
											<td class="text-center">${item.saldo}</td>
									</tr>`;
              } else {
                dfacturas += `
									<tr class="success">
											<td class="text-center">
													<a href="#"
															data-toggle="modal"
															class="btn btn-flat btn-default btn-sm"
															data-target="#mdInfo"
															data-href="${this.urlshowfacturas}/show/${item.factNum}">
															${item.factNum}
													</a>
											</td>
											<td class="text-center">${item.fecEmis}</td>
											<td class="text-center">${item.fecVenc}</td>
											<td class="text-center">${item.saldo}</td>
									</tr>`;
              }
            });
            $(".facturas").html(dfacturas);
            $("#modalFacturas").modal("toggle");
          }
          $("#cerrar").addClass("hidden");
          let msj = "";
          if (this.vendedor == 1) {
            if (countOverdueBills == 1) {
              if (response.data[0].diasEmis >= 7) {
                msj = ` 
                                            <strong><i class="fa fa-warning text-red"></i>Usted está montando una Orden de Pedido a un cliente con al menos una factura vencida. ¿Desea continuar? </strong><br>`;
                $("#cerrar").removeClass("hidden");
              }
            }
            if (countOverdueBills >= 2) {
              msj = ` 
                                        <strong><i class="fa fa-warning text-red"></i>Usted está montando una Orden de Pedido a un cliente con al menos una factura vencida. ¿Desea continuar? </strong><br>`;
              $("#cerrar").removeClass("hidden");
            }
            $(".msj").html(msj);
          } else {
            if (countOverdueBills == 1) {
              if (response.data[0].diasEmis >= 7) {
                msj = ` 
                                            <strong><i class="fa fa-warning text-red"></i>No se puede generar pedido al cliente porque la factura tiene más de 6 días. Por favor contactar a su respectivo coordinador.</strong><br>`;
                $("#btnOrderCreateSave").prop("disabled", true);
                $(".modal-footer").html(
                  `<a class="btn btn-flat btn-primary" href="${this.urlshowprod}">Aceptar</a>`
                );
                $("#close").removeAttr("data-dismiss");
                $("#close").prop("href", `${this.urlshowprod}`);
              }
            }
            if (countOverdueBills >= 2) {
              msj = ` 
                                        <strong><i class="fa fa-warning text-red"></i>No se puede generar pedido al cliente porque posee más de 1 factura. Por favor contactar a su respectivo coordinador.</strong><br>`;
              $("#btnOrderCreateSave").prop("disabled", true);
              $(".modal-footer").html(
                `<a class="btn btn-flat btn-primary" href="${this.urlshowprod}">Aceptar</a>`
              );
              $("#close").removeAttr("data-dismiss");
              $("#close").prop("href", `${this.urlshowprod}`);
            }
            $(".msj").html(msj);
          }
        })
        .catch((e) => {
          this.notificacion({
            icon: "fa fa-ban",
            title: "Error",
            e: e,
            type: "danger",
          });
        });
    },
    getArt: function () {
      this.articulos = null;
      this.msgCargando = "Cargando artículos...";
      axios
        .post(this.urlarticulos)
        .then((response) => {
          this.articulos = response.data;
          this.ordenRows(this.numorden);
          if (this.pedido == false) {
            this.ocultaOverlay = true;
          }
        })
        .catch((e) => {
          this.notificacion({
            icon: "fa fa-ban",
            title: "Error",
            e: e,
            type: "danger",
          });
        });
      this.seleccionados();
    },
    ordenRows: function (orden) {
      if (orden === "") return;
      axios
        .post(this.urlreng, { id: orden })
        .then((response) => {
          this.rows = response.data;
          this.agregar = true;
          this.ocultaOverlay = true;
          this.totalPedidoBS;
          this.totalPedidoDS;
          setTimeout(function () {
            let elements = document.getElementsByClassName("item");
            for (let i = 0; i < elements.length; i++) {
              let element = elements[i];
              element.style.fontSize = "1.5rem";
            }
          }, 800);
        })
        .catch((e) => {
          this.notificacion({
            icon: "fa fa-ban",
            title: "Error",
            e: e,
            type: "danger",
          });
        });
    },
    totRengBS: function (index) {
      this.rows[index].totRengBs =
        this.rows[index].precio * this.rows[index].cantidad;
    },
    totRengDS: function (index) {
      this.rows[index].totRengDs =
        this.rows[index].precio_divisa * this.rows[index].cantidad;
    },
    valRow: function (index) {
      if (this.condicion != "") {
        this.articulo = this.articulos.filter(
          (item) => item.value === this.rows[index].articulo
        );
        if (this.articulo.length !== 0) {
          this.rows[index].nombre = this.articulo[0].label;
          this.rows[index].cantidad =
            this.rows[index].cantidad === 0 ? 0 : this.rows[index].cantidad;
          this.rows[index].totRengBs =
            this.rows[index].totRengBs === 0 ? 0 : this.rows[index].totRengBs;
          this.rows[index].totRengDs =
            this.rows[index].totRengDs === 0 ? 0 : this.rows[index].totRengDs;
          this.rows[index].cantMax = this.articulo[0].stock;
          this.rows[index].imagen = this.articulo[0].imagen;
          if (this.condicion == "2") {
            this.rows[index].precio =
              this.articulo[0].precio3 == null ? 0 : this.articulo[0].precio3;
            this.rows[index].precio_divisa =
              this.articulo[0].precio_divisa3 == null
                ? 0
                : this.articulo[0].precio_divisa3;
          } else if (this.condicion == "3") {
            this.rows[index].precio =
              this.articulo[0].precio4 == null ? 0 : this.articulo[0].precio4;
            this.rows[index].precio_divisa =
              this.articulo[0].precio_divisa4 == null
                ? 0
                : this.articulo[0].precio_divisa4;
          } else {
            this.rows[index].precio =
              this.articulo[0].precio1 == null ? 0 : this.articulo[0].precio1;
            this.rows[index].precio_divisa =
              this.articulo[0].precio_divisa1 == null
                ? 0
                : this.articulo[0].precio_divisa1;
          }
        } else {
          this.rows[index].nombre = "";
          this.rows[index].cantidad = 0;
          this.rows[index].totRengBs = 0;
          this.rows[index].totRengDs = 0;
          this.rows[index].cantMax = 0;
          this.rows[index].imagen = "/logo.png";
          this.rows[index].precio = 0;
          this.rows[index].precio_divisa = 0;
        }
        let a = this.rows[index].articulo;
        if (this.rows[index].cantidad > this.rows[index].cantMax) {
          this.rows[index].cantidad = null;
          let msj =
            "Superó la cantidad máxima del artículo en el ítem # " +
            (index + 1);
          this.notificacion({
            icon: "fa fa-ban",
            title: "Advertencia",
            e: msj,
            type: "warning",
          });
        }
        let rep = this.rows.filter(function (item) {
          return item.articulo === a;
        });
        this.agregar =
          rep.length === 1 && a !== "" && this.rows[index].cantidad > 0;
        if (!(rep.length === 1)) {
          this.rows[index].articulo = "";
          let msj =
            "El artículo en el ítem #" +
            (index + 1) +
            " está repetido, seleccione otro.";
          this.notificacion({
            icon: "fa fa-ban",
            title: "Advertencia",
            e: msj,
            type: "warning",
          });
        }
      } else {
        this.notificacion({
          icon: "fa fa-ban",
          title: "Advertencia",
          e: "Debe seleccionar un cliente",
          type: "warning",
        });
      }
    },
    addRow: function () {
      try {
        this.rows.splice(this.rows.length + 1, 0, {
          articulo: "",
          cantidad: 0,
          precio: 0.0,
          precio_divisa: 0.0,
          totRengBs: 0,
          totRengDs: 0,
          cantMax: 0,
          imagen: "/logo.png",
        });
        this.agregar = false;
        setTimeout(function () {
          let elements = document.getElementsByClassName("item");
          for (let i = 0; i < elements.length; i++) {
            let element = elements[i];
            element.style.fontSize = "1.5rem";
          }
        }, 800);
      } catch (e) {
        this.notificacion({
          icon: "fa fa-ban",
          title: "Error",
          e: e,
          type: "danger",
        });
      }
    },
    removeRow: function (index) {
      this.rows.splice(index, 1);
      this.agregar = true;
    },
  },
};
</script>