<template>
  <div class="box box-default box-solid">
    <form id="form" v-bind:action="urlstore" method="post">
      <input type="hidden" name="_token" :value="csrf" />
      <div class="row">
        <div class="col-md-7">
          <div class="form-group">
            <label for="cliente">Cliente</label>
            <div v-if="!clientes">
              <i class="fa fa-2x fa-spin fa-refresh"></i> Cargando clientes...
            </div>
            <div v-else>
              <model-select
                :options="clientes"
                v-model="cliente"
                placeholder="[Seleccione un cliente]"
              ></model-select>
              <input
                type="text"
                name="cliente"
                id="cliente"
                v-model="cliente.value"
                hidden
              />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label" for="fecha">Fecha de Cobro</label>
            <div class="input-group">
              <input
                class="form-control datepicker"
                type="text"
                name="fecha"
                id="fecha"
                :value="fecha"
              />
              <span class="input-group-btn">
                <button
                  class="btn btn-md btn-primary"
                  type="button"
                  @click="getFacturas()"
                >
                  Consultar
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="box-body no-padding table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <!--<th class="text-center"></th>-->
              <th class="text-center">#</th>
              <th class="text-center">Saldo/Abonar</th>
              <th class="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in facturas" :key="index">
              <td class="text-center">
                <input
                  type="text"
                  name="factNum[]"
                  :value="data.factNum"
                  hidden
                />
                <a
                  href="#"
                  data-toggle="modal"
                  class="btn btn-flat btn-default btn-sm"
                  data-target="#mdInfo"
                  v-bind:data-href="urlfacturas + '/show/' + data.factNum"
                >
                  {{ data.factNum }}
                </a>
              </td>
              <td class="text-center">
                <div class="row">
                  <input
                    type="text"
                    name="diasEmis[]"
                    :value="data.diasEmis"
                    hidden
                  />
                  <!--Código Cliente y Vendedor-->
                  <input
                    type="text"
                    name="cocli[]"
                    :value="data.coCli"
                    hidden
                  />
                  <input
                    type="text"
                    name="coven[]"
                    :value="data.coVen"
                    hidden
                  />

                  <input
                    type="text"
                    name="totNeto[]"
                    :value="data.totNeto"
                    hidden
                  />
                  <input type="text" name="iva[]" :value="data.iva" hidden />
                  <input
                    type="text"
                    name="saldo[]"
                    :value="data.saldo"
                    hidden
                  />{{ data.saldo }}
                  <button
                    class="btn btn-sm btn-flat text-blue"
                    type="button"
                    @click="
                      cargarSaldo(index, data.saldo);
                      totalAb();
                    "
                  >
                    +
                  </button>
                  <div class="row">
                    Monto Abonar:
                    <div class="input-group">
                      <span class="input-group-addon">
                        <input type="checkbox" name="allCommissions[]" />
                      </span>
                      <input
                        type="number"
                        step="0.01"
                        min="0"
                        :max="data.saldo.replace(/\./g, '').replace(',', '.')"
                        class="form-control"
                        name="abonar[]"
                        :value="data.montoAbonar"
                        @change="
                          abonar(index, $event.target.value);
                          totalAb();
                        "
                      />
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div class="btn-group" role="group" aria-label="acciones">
                  <a
                    v-if="!data.notificada"
                    href="#"
                    class="btn btn-flat btn-success btn-xs"
                    @click="notificar(data.factNum, index)"
                  >
                    M
                  </a>
                  <a
                    v-else
                    href="#"
                    class="btn btn-flat btn-info btn-xs"
                    @click="notificar(data.factNum, index)"
                  >
                    D
                  </a>

                  <a
                    v-if="!data.publicada"
                    href="#"
                    class="btn btn-flat btn-default btn-xs publicar"
                    @click="publicar(data.factNum, index)"
                  >
                    <i class="fa fa-square-o"></i
                  ></a>
                  <a
                    v-else
                    href="#"
                    class="btn btn-flat btn-default btn-xs publicar"
                    @click="publicar(data.factNum, index)"
                  >
                    <i class="fa fa-check-square-o"></i
                  ></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <hr />
          <div id="campos" class="hidden">
            <div class="col-sm-12">
              <label for="formPag">Forma de Pago</label>
              <select
                v-model="formaPag"
                @change="ajuste()"
                class="form-control"
                name="formPag"
                id="formPag"
                required
              >
                <option value="1">Efectivo</option>
                <option value="3">Depósito</option>
              </select>
            </div>
            <div v-if="formaPag !== '1'" class="col-sm-12">
              <label>Referencia</label>
              <input class="form-control" type="text" name="ref" required />
            </div>
            <div class="col-sm-12">
              <label>Caja/Cuenta</label>
              <select
                v-if="formaPag !== '3'"
                class="form-control"
                name="cajaCta"
                required
              >
                <option
                  v-for="opt in cajas"
                  :value="opt.codCaja + ' - ' + opt.descrip"
                  :key="opt.codCaja"
                >
                  {{ opt.codCaja }} - {{ opt.descrip }}
                </option>
              </select>
              <select v-else class="form-control" name="cajaCta" required>
                <option
                  v-for="opt in cuentas"
                  :value="opt.codCta + ' - ' + opt.numCta"
                  :key="opt.codCta + '-' + opt.numCta"
                >
                  {{ opt.codCta }} - {{ opt.numCta }}
                </option>
              </select>
            </div>
            <div class="col-sm-12">
              <label class="text-center" for="mont">Total Abonado</label>
              <input
                class="form-control"
                type="number"
                step="0.01"
                :min="this.totalfact"
                name="mont"
                id="mont"
                v-model="montoAb"
                @change="ajuste()"
              />
            </div>

            <input
              type="text"
              id="ajustar"
              name="ajustar"
              v-model="this.ajustes"
              hidden
            />
            <input
              type="text"
              id="diff"
              name="diff"
              v-model="this.diff"
              hidden
            />
            <input
              type="text"
              id="totalfact"
              name="totalfact"
              v-model="this.totalfact"
              hidden
            />

            <div id="ajust" class="col-sm-12 hidden">
              <span style="color: black"
                >Existe una diferencia de
                <span style="color: darkred">
                  <b>'{{ this.diff }}'</b>
                </span>
                que se cargará como un Ajuste Positivo Manual</span
              >
            </div>
          </div>
        </div>
      </div>
      <div v-bind:class="{ hidden: ocultaOverlay, overlay: true }">
        <span
          ><i class="fa fa-2x fa-spin fa-refresh"></i> {{ msgCargando }}</span
        >
      </div>
      <div class="box-footer">
        <a class="btn btn-flat btn-default" v-bind:href="urlhome"
          ><i class="fa fa-arrow-left"></i> Volver</a
        >
        <button
          v-if="
            !this.facturas || this.facturas.length == 0 || this.montoAb == 0
          "
          type="submit"
          class="btn btn-flat btn-primary"
          disabled
        >
          <i class="fa fa-save"> </i> Guardar
        </button>
        <button
          v-else
          type="submit"
          class="btn btn-flat btn-primary btnGuardar"
          data-loading-text="Guardando..."
        >
          <i class="fa fa-save"> </i> Guardar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select";
import Collections from "./Collections";
export default {
  components: { ModelSelect },
  extends: Collections,
};
</script>