import { render, staticRenderFns } from "./pedido_orden_reng.vue?vue&type=template&id=6a0733d5"
import script from "./pedido_orden_reng.vue?vue&type=script&lang=js"
export * from "./pedido_orden_reng.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports