
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import VueResource from 'vue-resource';
import Vue from 'vue';
import ArticleComponent from './components/articulos/articulo.vue';
import PedidoRengComponent from './components/pedidos/pedido_reng.vue';
import PedidoOrdenRengComponent from './components/pedidos/pedido_orden_reng.vue';
import CompraOrdenRengComponent from './components/ordenCompra/compra_orden_reng.vue';
import SelectClientesComponent from './components/cmbcliente.vue';
import SelectVendedoresComponent from './components/cmbvendedor.vue';
import SelectAlmacenesComponent from './components/cmbalmacen.vue';
import SelectProveedoresComponent from './components/cmbproveedor.vue';
import SelectArticulosComponent from './components/cmbarticulo.vue';
import CobroComponent from './components/cobro/notificacioncobro.vue';
import DespachoComponent from './components/despacho/despacho.vue';
import FirmaComponent from './components/visitas/firma.vue';
import ReciboRengComponent from './components/recibo_reng.vue';

import MobilePedidoOrdenVDRengComponent from './components/pedidos/mobile_pedido_orden_vd_reng.vue';
import MobilePedidoOrdenRengComponent from './components/pedidos/mobile_pedido_orden_reng.vue';
import MobileCobroComponent from './components/cobro/mobile_notificacioncobro.vue';

Vue.use(VueResource);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    components: {
        'articulo': ArticleComponent,
        'pedido-reng': PedidoRengComponent,
        'pedido-orden-reng': PedidoOrdenRengComponent,
        'mobile-pedido-orden-reng': MobilePedidoOrdenRengComponent,
        'compra-orden-reng': CompraOrdenRengComponent,
        'select-clientes': SelectClientesComponent,
        'select-vendedores': SelectVendedoresComponent,
        'select-almacenes': SelectAlmacenesComponent,
        'select-proveedores': SelectProveedoresComponent,
        'select-articulos': SelectArticulosComponent,
        'cobro': CobroComponent,
        'despacho': DespachoComponent,
        'firma': FirmaComponent,
        'recibo-reng': ReciboRengComponent,
        'mobile-pedido-VD-orden-reng': MobilePedidoOrdenVDRengComponent,
        'mobile-cobro': MobileCobroComponent
    }
});

window.Vue = app;
