<template>
  <div v-if="!articulos">
    <i class="fa fa-2x fa-spin fa-refresh"></i> Cargando artículos...
  </div>
  <div v-else>
    <model-select
      :options="articulos"
      v-model="articulo"
      placeholder="[Seleccione un artículo]"
    ></model-select>
    <input
      type="text"
      name="articulo"
      id="articulo"
      v-model="articulo"
      hidden
    />
  </div>
</template>
<script>
import { ModelSelect } from "vue-search-select";

export default {
  components: { ModelSelect },
  props: {
    urlarticulos: {
      type: String,
      default: "/tabla/articulos",
    },
  },
  mounted() {
    this.getArt();
  },
  data() {
    return {
      articulo: "",
      articulos: [
        {
          text: "Seleccione",
          value: "",
          ref: "",
          stock: 0,
          precio: 0.0,
        },
      ],
    };
  },
  methods: {
    getArt: function () {
      this.articulos = null;
      axios
        .post(this.urlarticulos)
        .then((response) => {
          let data = $.map(response.data, function (obj) {
            obj.text = obj.label;
            return obj;
          });
          this.articulos = data;
        })
        .catch((e) => {
          $.notify(
            {
              icon: "fa fa-ban",
              title: "Error",
              message: e,
            },
            {
              element: "body",
              type: "danger",
              template:
                '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                '<h4><button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                '<span data-notify="icon"></span> ' +
                '<span data-notify="title">{1}</span></h4> ' +
                '<span data-notify="message">{2}</span>' +
                '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                "</div>" +
                '<a href="{3}" target="{4}" data-notify="url"></a>' +
                "</div>",
            }
          );
        });
    },
  },
};
</script>