export function getProducts() {
  this.articulos = null;
  this.msgCargando = "Cargando artículos...";
  axios
    .post(this.urlarticulos)
    .then((response) => {
      this.articulos = response.data;
      this.ordenRows(this.numorden);
      if (this.pedido == false) {
        this.ocultaOverlay = true;
      }
    })
    .catch((e) => {
      this.notificacion({
        icon: "fa fa-ban",
        title: "Error",
        e: e,
        type: "danger",
      });
    });
  this.seleccionados();
}
