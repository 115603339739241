<template>
  <div class="box-body">
    <div v-if="numorden != ''" class="box-body">
      <div class="box box-default box-solid">
        <div class="col-md-7">
          <div class="form-group">
            <h3>Cliente</h3>
            <label class="dclient control-label">Nombre o Razon:</label>
            {{ nombrecli }}
            <br />
            <label class="dclient control-label">RIF:</label> {{ rifcli }}
            <button
              v-if="pedido == false"
              class="btn btn-primary btn-sm btn-flat"
              type="button"
              data-toggle="collapse"
              data-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
              style="margin-left: 15px"
            >
              Cambiar
            </button>
          </div>
          <div v-if="pedido != false" class="form-group">
            <label class="control-label">Transporte:</label>
            <div v-if="!transportes">
              <i class="fa fa-2x fa-spin fa-refresh"></i> Cargando
              transportes...
            </div>
            <select
              v-else
              class="form-control"
              name="transporte"
              id="transporte"
              required
            >
              <option value="" selected>[Seleccione un transporte]</option>
              <option
                v-for="opt in transportes"
                :value="opt.value"
                :key="opt.value"
              >
                {{ opt.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-5">
          <div v-if="pedido == false" class="form-group pull-right">
            <h3>
              Orden de Pedido #<span class="text-red"> {{ numorden }}</span>
            </h3>
          </div>
          <div v-else class="form-group pull-right">
            <h3>
              Orden #<span class="text-red"> {{ numorden }}</span>
            </h3>
            <label>Vendedor</label> {{ nombreven }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="pedido == false" class="form-group">
      <div v-if="numorden != ''" class="collapse" id="collapseExample">
        <div class="row">
          <div class="col-md-9">
            <label class="control-label" for="cliente">Cliente</label>
            <div v-if="!clientes">
              <i class="fa fa-2x fa-spin fa-refresh"></i> Cargando clientes...
            </div>
            <div v-else>
              <model-select
                :options="clientes"
                v-model="cliente"
                placeholder="[Seleccione un cliente]"
                @input="valCli()"
              ></model-select>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-md-9">
          <label class="control-label" for="cliente">Cliente</label>
          <div v-if="!clientes">
            <i class="fa fa-2x fa-spin fa-refresh"></i> Cargando clientes...
          </div>
          <div v-else>
            <model-select
              :options="clientes"
              v-model="cliente"
              placeholder="[Seleccione un cliente]"
              @input="valCli()"
            ></model-select>
          </div>
        </div>
        <div v-if="numorden == ''" class="col-md-3">
          <label class="control-label" for="fecha">Fecha</label>
          <input
            class="form-control datepicker"
            readonly
            type="text"
            name="fecha"
            id="fecha"
            :value="fecha"
          />
        </div>
      </div>
      <input
        type="text"
        name="cliente"
        id="cliente"
        v-model="cliente.value"
        hidden
      />
    </div>
    <div class="form-group">
      <div class="table-responsive">
        <div class="box box-default box-solid">
          <div class="box-body no-padding table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th class="text-center">#</th>
                  <th class="text-center">Imagen</th>
                  <th class="text-center">Articulo</th>
                  <th class="text-center" width="250px">Cantidad</th>
                  <th class="text-center">Precio</th>
                  <th class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in rows" :key="index" track-by="$index">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center" v-if="row.articulo != ''">
                    <a
                      href="#"
                      data-toggle="modal"
                      class="btn btn-flat btn-default btn-sm"
                      data-target="#mdInfo"
                      :data-id="index"
                      v-bind:data-href="
                        urlshowprod + '/inventario/show/' + row.articulo
                      "
                    >
                      <img
                        v-bind:id="'mproductImg_' + index"
                        v-bind:src="urlshowprod + '/img/productos' + row.imagen"
                        v-bind:alt="row.articulo"
                        width="25"
                        height="25"
                      />
                    </a>
                  </td>
                  <td class="text-center" v-else>
                    <img
                      v-bind:src="urlshowprod + '/img/productos/logo.png'"
                      v-bind:alt="row.articulo"
                      width="35"
                      height="35"
                    />
                  </td>
                  <td>
                    <model-list-select
                      :list="articulos"
                      v-model="row.articulo"
                      option-value="value"
                      :custom-text="formatoLabel"
                      placeholder="[Seleccione un artículo]"
                      name="mArticulo[]"
                      @input="valRow(index)"
                    />
                    <input
                      type="text"
                      name="articulo[]"
                      id="articulo[]"
                      v-model="row.articulo"
                      hidden
                    />
                  </td>
                  <td>
                    <div class="input-group">
                      <input
                        class="form-control"
                        type="number"
                        v-bind:max="row.cantMax"
                        v-bind:min="0.5"
                        name="cantidad[]"
                        step="0.5"
                        lang="en"
                        required
                        @change="
                          valRow(index);
													totRengBS(index);
                          totRengDS(index);
                        "
                        @keydown.enter="$event.preventDefault()"
                        placeholder="0"
                        v-model="row.cantidad"
                      />
                      <span class="input-group-addon"
                        >max. {{ row.cantMax }}</span
                      >
                    </div>
                  </td>
                  <td class="text-right">
                    {{ formatoMonto(row.precio_divisa * (row.hasTax ? 1.16 : 1)) }}
										<input
                      type="text"
                      name="precio[]"
                      v-model="row.precio"
                      class="form-control hidden"
                      readonly
                    />
                    <input
                      type="text"
                      name="precioDs[]"
                      v-model="row.precio_divisa"
                      class="form-control hidden"
                      readonly
                    />
                  </td>
                  <td class="text-center">
                    <button
                      @click="removeRow(index)"
                      type="button"
                      class="btn btn-link text-red"
                    >
                      <i class="fa fa-2x fa-times"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="6">
                    <button
                      type="button"
                      @click="addRow()"
                      class="btn btn-flat btn-primary btn-block"
                      :disabled="!agregar"
                    >
                      AGREGAR <i class="fa fa-list-ul"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <th colspan="4">
                    <div class="form-group">
                      <label for="comentario"
                        >Informaci&oacute;n Adicional</label
                      >
                      <textarea
                        name="comentario"
                        id="comentario"
                        rows="4"
                        class="form-control"
                        placeholder=""
                        maxlength="60"
                        v-model="adicional"
                      ></textarea>
                    </div>
                  </th>
                  <th colspan="2">
                    <h2 class="text-right">
                      Total: {{ formatoMonto(totalPedidoDS) }}
                    </h2>
										<input
                      type="text"
                      name="totNeto"
                      id="totNeto"
                      v-model="totalPedidoBS"
                      class="hidden"
                    />
                    <input
                      type="text"
                      name="totNetoDs"
                      id="totNetoDs"
                      v-model="totalPedidoDS"
                      hidden
                    />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div v-bind:class="{ hidden: ocultaOverlay, overlay: true }">
            <span
              ><i class="fa fa-2x fa-spin fa-refresh"></i
              >{{ msgCargando }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ModelListSelect, ModelSelect } from "vue-search-select";
import CustomerOrder from './CustomerOrder'
export default {
  components: { ModelListSelect, ModelSelect },
  extends: CustomerOrder,
};
</script>